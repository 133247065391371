
import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from './../../service/Server'
import Select from "../../Select";

const BASEURL = Server.baseUrl;

const intitialState = {
    name: '',
    email: '',
    mobile: '',
    user_id: '',
    department_id: '',
    designation_id: '',
    level_id: '',
    user_type: '',
    username: '',
    id: '',
    isLoaded: false,
    error: false,
    errorMessage: {},
    users: [],
    isBtnDisabled: false,
    departments: [],
    designations: [],
    levelData: [],

};

class UserRegistration extends Component {

    constructor(props) {
        super(props)
        this.myForm = React.createRef()
        this.state = intitialState;
        document.title = "User";

    }

    openModal = () => {
        this.setState({
            name: '',
            email: '',
            mobile: '',
            department_id: '',
            designation_id: '',
            user_type: '',
            username: '',
            level_id: '',
            id: '',
            error: false,
        });
    }

    getUsers = (callBack = () => { }) => {
        axios.get(`${BASEURL}/getAllUser`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    users: getRes.data,
                    isLoaded: true,
                }, () => {
                    callBack();
                });

                // this.setState({
                //     name:'value' 
                // },() => {
                //     console.log(this.state.name);
                // });

            })
            .catch(error => console.log(error));
    }

    getDepartments = () => {
        axios.get(`${BASEURL}/getDepartmentDropdown`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    departments: getRes.data,
                    isLoaded: true,
                })
            })
            .catch(error => console.log(error));
    }

    getDesignations = () => {
        axios.get(`${BASEURL}/getDesignations`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    designations: getRes.data,
                    isLoaded: true,
                })
            })
            .catch(error => console.log(error));
    }

    getLevelData = () => {
        axios.get(`${BASEURL}/getUserLevel`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    levelData: getRes.data,
                    isLoaded: true,
                })
            })
            .catch(error => console.log(error));
    }



    componentDidMount() {
        this.getDepartments();
        this.getDesignations();
        this.getUsers();
        this.getLevelData();

    }



    update = (index) => {

        var user = this.state.users[index];

        this.setState({
            name: user.name,
            email: user.email,
            mobile: user.mobile,
            department_id: user.department_id,
            designation_id: user.designation_id,
            user_type: user.user_type,
            level_id: user.level_id,
            username: user.username,
            id: user.id,
            error: false,
        });
    }

    delete = (id) => {
        var userid = id;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {

                axios.post(`${BASEURL}/userDelete`, { id: userid })
                    .then(res => {
                        var getRes = res.data;
                        if (getRes.status === 'success') {
                            this.setState({
                                isLoaded: false
                            });
                            this.getUsers(() => {Swal.fire(
                                'Deleted!',
                                'User Deleted Successfully',
                                'success'
                            )});
                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                'User delete is fail',
                                'warning'
                            )
                        }


                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }

            else {
                Swal.fire(
                    'Cancelled!',
                    'User delete is cancel',
                    'error'
                )
            }
        })

    }

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    submitHandler = (event) => {

        event.preventDefault();

        var userdata = {
            "name": this.state.name,
            "email": this.state.email,
            "mobile": this.state.mobile,
            "department_id": this.state.department_id,
            "designation_id": this.state.designation_id,
            "user_id": Math.floor(Math.random() * 500000),
            "user_type": this.state.user_type,
            "level_id": this.state.level_id,
            "username": this.state.username,
            "id": this.state.id
        };

        this.setState({
            isBtnDisabled: true
        });

        if (this.state.id === '') {
            axios.post(`${BASEURL}/register`, userdata)
                .then(res => {
                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            name: '',
                            email: '',
                            mobile: '',
                            user_id: '',
                            department_id: '',
                            designation_id: '',
                            user_type: '',
                            level_id: '',
                            username: '',
                            id: '',
                            isLoaded: false,
                            error: false,
                            errorMessage: {},
                            users: [],
                            isBtnDisabled: false,
                        });

                        this.getUsers(() => {
                            Swal.fire(
                                'Added!',
                                'User Created Successfully',
                                'success'
                            )
                        });

                        this.modalClose();

                        

                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }

        else {
            axios.post(`${BASEURL}/userUpdate`, userdata)
                .then(res => {

                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            name: '',
                            email: '',
                            mobile: '',
                            user_id: '',
                            department_id: '',
                            designation_id: '',
                            user_type: '',
                            level_id: '',
                            username: '',
                            id: '',
                            isLoaded: false,
                            error: false,
                            errorMessage: {},
                            users: [],
                            isBtnDisabled: false,
                        });

                        this.getUsers(() => {
                            Swal.fire(
                                'Updated',
                                'User Updated Successfully',
                                'success'
                            )
                        });

                        this.modalClose();

                    }
                    else {
                        var errors = getRes.message;
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false,
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }
    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

    }

    dropDownUpdate = (id) => {
        this.setState({
            department_id: id
        })

    }

    render() {
        const ops = this.state.departments;
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        User
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-cogs"></i> Configuration</li>
                        <li className="active">User</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">User List</h3>
                                        <button onClick={this.openModal} style={{ float: "right" }} type="button" className="btn btn-primary btn-md" data-toggle="modal" data-target="#modal-default">
                                            <i className="fa fa-plus"></i> New User
                                        </button>
                                    </div>
                                    <div className="box-body">

                                        <table style={{ width: "100%" }} className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Name</th>
                                                    <th>User Type</th>
                                                    {/*<th>User Level</th>*/}
                                                    <th>Department</th>
                                                    <th>Designation</th>
                                                    <th>Username</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.users.map((user, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {user.name}
                                                            <br/>
                                                            {user.email}
                                                            <br/>
                                                            {user.mobile}
                                                        </td>
                                                        <td>{user.user_type_name}</td>
                                                        {/*<td>{user.user_level_name}</td>*/}
                                                        <td>{user.department_name}</td>
                                                        <td>{user.designation_name}</td>
                                                        <td>{user.username}</td>
                                                        <td>
                                                            <button data-toggle="modal" data-target="#modal-default" onClick={() => { this.update(index) }} className="btn btn-info btn-sm"><i className="fa fa-pencil"></i> Update</button>
                                                            &nbsp;
                                                            <button onClick={() => { this.delete(user.id) }} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i> Delete</button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Loader>


                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">

                            <form ref={this.myForm} role="form" onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">User Registration</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            <div className="form-group">
                                                <label htmlFor="name">Full Name</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.name} name="name" placeholder="Enter Full Name" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" onChange={this.changeHandler} className="form-control" value={this.state.email} name="email" placeholder="Enter Email Address" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="mobile">Mobile</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.mobile} name="mobile" placeholder="Enter Mobile" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="department_id">Department</label>
                                                <Select
                                                    onChange={(e)=>{this.dropDownUpdate(e.value)}}
                                                    options={ops}
                                                    placeholder="Select Department"
                                                    value={this.state.department_id} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="designation_id">Designation</label>
                                                <select value={this.state.designation_id} onChange={this.changeHandler} className="form-control" name="designation_id">
                                                    <option value="">Designation</option>
                                                    {
                                                        this.state.designations.map((designation, index) =>
                                                            <option key={index} value={designation.id}>{designation.name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>




                                            <div className="form-group">
                                                <label htmlFor="user_type">User Type</label>
                                                <select value={this.state.user_type} onChange={this.changeHandler} className="form-control" name="user_type">
                                                    <option value="">User Type</option>
                                                    <option value="2">Master Admin</option>
                                                    <option value="3">System Admin</option>
                                                    <option value="4">Section User</option>
                                                </select>
                                            </div>


                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="designation_id">User Level</label>*/}
                                            {/*    <select value={this.state.level_id} onChange={this.changeHandler} className="form-control" name="level_id">*/}
                                            {/*        <option value="">User Level</option>*/}
                                            {/*        {*/}
                                            {/*            this.state.levelData.map((designation, index) =>*/}
                                            {/*                <option key={index} value={designation.value}>{designation.label}</option>*/}
                                            {/*            )*/}
                                            {/*        }*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}

                                            <div className="form-group">
                                                <label htmlFor="username">Username</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.username} name="username" placeholder="Enter Username" />
                                            </div>

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">{this.state.isBtnDisabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>


                </section>
            </div>
        );

    }
}

export default UserRegistration;
