import React, { Component } from 'react';
// import GroupedOptionsField from './GroupedOptionsField';
import Loader from 'react-loader';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactImageFallback from "react-image-fallback";
import Server from '../../service/Server';
import { NavLink , withRouter  } from 'react-router-dom';

import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import Select from '../../Select';
import 'react-select-plus/dist/react-select-plus.css';

document.title = "Scan File";
const BASEURL = Server.baseUrl;
const FileDir = BASEURL + "/getFile/";

const options =  [{
    label: 'Primary Colors', options: [
        { label: 'Yellow', value: 'yellow' },
        { label: 'Red', value: 'red' },
        { label: 'Blue', value: 'blue' }
    ]
    },
    {
        label: 'Secondary Colors', options: [
            { label: 'Orange', value: 'orange' },
            {
                label: 'Purple', options: [
                    { label: 'Light Purple', value: 'light_purple' },
                    { label: 'Medium Purple', value: 'medium_purple' },
                    { label: 'Dark Purple', value: 'dark_purple' }
                ]
            },
            { label: 'Green', value: 'green' }
        ]
    },
    {
        label: 'White',
        value: 'white',
    }];

const  selectedOption  = null;

class ScanFile extends Component {
    constructor(props) {
        super(props);
        this.myForm = React.createRef();
        this.state = {
            isLoaded: false,
            disabled:false,
            search_code: this.props.match.params.id,
            categories: [],
            fileList: [],
            options:options
        };

    }



    getFileList = () => {
        axios.get(`${BASEURL}/getFileList`, {
            params: {
                code: this.state.search_code
            }
        })
            .then(res => {
                var getRes = res.data;
                this.setState({
                    isLoaded: true,
                    fileList: getRes.data
                });
            })
    }
    componentDidMount() {
        axios.get(`${BASEURL}/getCategoriesDropdown`)
            .then(res => {
                var category = res.data;
                // return;
                this.setState({
                    categories: category.data
                }, () => {
                    this.getFileList();
                });
            })
    }

    componentWillReceiveProps(newProps) {
        
        this.setState({
            search_code: newProps.match.params.id,
            // isLoaded: false
        },()=>{
            this.getFileList();
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true
        });
        axios.post(`${BASEURL}/scanFileEntry`,{
            files: this.state.fileList
        })
        .then(res => {
            let getRes = res.data;
            if(getRes.status==='success') {
                this.setState({
                    disabled: false
                });
                Swal.fire(
                    'Scaned!',
                    'File Scan Successfully.',
                    'success'
                )
                axios.get(`${BASEURL}/getScanIncompleteCode`,{
                    params:{
                        code:this.state.search_code
                    }
                })
                .then(response => {
                    let scanRes = response.data;
                    if(scanRes.status==='success') {
                        this.props.history.push('/scan_file/'+scanRes.data[0].code);
                    }
                })

            }
        }) 
    }


    changeHandler = (id,event) => {
        // console.log(event.value);
        // return;
        let category_id = event;
        let newFileList = this.state.fileList.map(item => {
            if (id === item.id) {
                item.category_id = category_id;
                item.categoryName = this.getCategoryName(category_id);
                return item;
            }
            else {
                return item;
            }
        });

        this.setState({
            fileList: newFileList
        });

    }


    getCategoryName = (category_id) => {
        let category = this.state.categories.filter(function (item) {
            return item.value === category_id
        });

        if (category.length > 0) {
            return category[0].label;
        }
        else {
            return "Unknown Category";
        }
    }


    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }


    handleChange = (selectedOption) => {
        // this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    }

    onChange = (value) => {
        this.setState({ value });
        console.log('Option Groups Select value changed to', value);
    }

    render() {
        const ops = this.state.categories;
        return (
            <div className="content-wrapper">
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                        <form onSubmit={this.submitHandler}>
                            <div className="row">
                                <div className="col-lg-12 col-xs-12 col-md-12">

                                    <div className="box box-default">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">File Scan Code-<b>{this.state.search_code}</b></h3>
                                        </div>

                                        <div className="box-body" style={{ minHeight: "300px" }}>

                                            <div className="row">
                                                {
                                                    this.state.fileList.map((item, index) =>

                                                        <div key={index} className="col-md-3 col-lg-3 col-xs-3">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading">
                                                                    <b>{ item.category_id > 0 ? item.categoryName : "Unknown Category" }</b>
                                                                </div>
                                                                <div className="panel-body">
                                                                    <ReactImageFallback
                                                                        src={item.file_path}
                                                                        fallbackImage={'dist/img/file_default.png'}
                                                                        initialImage={'dist/img/fallback.gif'}
                                                                        alt="cool image should be here"
                                                                        style={{ width: "100%", height: "200px" }}
                                                                        className="img-thumnail" />
                                                                </div>
                                                                <div className="panel-footer">
                                                                    {/* <div style={{ float: "left" }}>
                                                                    <button type="button" data-toggle="modal" data-target="#myModal" className="btn btn-sm btn-default"><i className="fa fa-eye"></i> View</button>
                                                                </div> */}

                                                                        {/*<GroupedOptionsField label="Select category"/>*/}

                                                                    <Select
                                                                        onChange={(e)=>{this.changeHandler(item.id,e ? e.value:0)}}
                                                                        options={ops}
                                                                        placeholder="Select Category"
                                                                        value={item.category_id} />

                                                                    <div className="clearfix"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </div>

                                        <div className="box-footer">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-4">
                                                
                                                </div>
                                                <div className="col-md-5 col-sm-5">
                                                    <button type="submit" disabled={this.state.disabled} className="btn btn-md btn-primary">{this.state.disabled?"Scaning":"Save & Next"}</button>
                                                    &nbsp;&nbsp;
                                                    <NavLink to="/manageFile">
                                                        <button type="button" className="btn btn-md btn-danger">Back</button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </form>
                    </Loader>
                    <div id="myModal" className="modal fade in" data-keyboard="false" data-backdrop="static" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    <h4 className="modal-title">File</h4>
                                </div>
                                <div className="modal-body">
                                    <img src="demo.png" alt="logo" />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(ScanFile);
