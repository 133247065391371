
import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from './../../service/Server'

const BASEURL = Server.baseUrl;

const intitialState = {
    name: '',
    sorting: '',
    id: '',
    isLoaded: false,
    error: false,
    errorMessage: {},
    designations: [],
    isBtnDisabled: false,
};

class Designation extends Component {

    constructor(props) {
        super(props)
        this.myForm = React.createRef()
        this.state = intitialState;
        document.title = "Designation"
    }

    openModal = () => {
        this.setState({
            name: '',
            sorting: '',
            id: '',
            error: false,
        });
    }

    getDesignations = (callBack = () => {}) => {
        axios.get(`${BASEURL}/getDesignations`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    designations: getRes.data,
                    isLoaded: true,
                },()=>{
                    callBack();
                })
            })
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getDesignations();
    }


    update = (index) => {

        var designation = this.state.designations[index];

        this.setState({
            name: designation.name,
            sorting: designation.sorting,
            id: designation.id,
            error: false,
        });
    }

    delete = (id) => {
        var designationId = id;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {

                axios.post(`${BASEURL}/designationDelete`, { id: designationId })
                    .then(res => {
                        var getRes = res.data;
                        if (getRes.status === 'success') {
                            this.getDesignations(() => {
                                Swal.fire(
                                    'Deleted!',
                                    'Designation Deleted Successfully',
                                    'success'
                                )
                            });
                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                'Designation delete is fail',
                                'warning'
                            )
                        }


                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }

            else {
                Swal.fire(
                    'Cancelled!',
                    'Designation delete is cancel',
                    'error'
                )
            }
        })

    }

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    submitHandler = (event) => {

        event.preventDefault();

        var designationData = {
            "name": this.state.name,
            "sorting": this.state.sorting,
            "id": this.state.id
        };

        this.setState({
            isBtnDisabled: true
        });

        if (this.state.id === '') {
            axios.post(`${BASEURL}/designationCreate`, designationData)
                .then(res => {
                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            ...intitialState
                        });

                        this.getDesignations(() => {
                            Swal.fire(
                                'Create!',
                                'Designation Created Successfully',
                                'success'
                            )
                        });

                        this.modalClose();

                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }

        else {
            axios.post(`${BASEURL}/designationUpdate`, designationData)
                .then(res => {

                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            ...intitialState
                        });

                        this.getDesignations(() => {
                            Swal.fire(
                                'Update!',
                                'Designation Updated Successfully',
                                'success'
                            )
                        });
                        this.modalClose();
                        

                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false,
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }
    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Designation
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-cogs"></i> Configuration</li>
                        <li className="active">Designation</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Designation List</h3>
                                        <button onClick={this.openModal} style={{ float: "right" }} type="button" className="btn btn-primary btn-md" data-toggle="modal" data-target="#modal-default">
                                            <i className="fa fa-plus"></i> New Designation
                                        </button>
                                    </div>
                                    <div className="box-body">
                                        <table style={{ width: "100%" }} className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Name</th>
                                                    <th>Sorting</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.designations.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.sorting}</td>
                                                        <td>
                                                            <button data-toggle="modal" data-target="#modal-default" onClick={()=> {this.update(index)}} className="btn btn-info btn-sm"><i className="fa fa-pencil"></i> Update</button>
                                                            &nbsp;
                                                            <button onClick={() => {this.delete(item.id)}} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i> Delete</button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Loader>



                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">

                            <form ref={this.myForm} role="form" onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">Designation</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            <div className="form-group">
                                                <label htmlFor="name">Designation Name</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.name} name="name" placeholder="Enter Designation Name" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="sorting">Sorting</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.sorting} name="sorting" placeholder="Enter Sorting" />
                                            </div>

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">{this.state.isBtnDisabled?'Saveing':'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>
                </section>
            </div>
        );

    }
}

export default Designation;
