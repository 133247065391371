import React, {Component} from 'react';

class Footer extends Component {

    render(){
        return(
            <footer className="main-footer">
                <div className="pull-right hidden-xs">
                    <b>Version</b> 2.4.13
                </div>
                <strong>Copyright &copy; 2014-2019 <a href="/">File Management</a>.</strong> All rights
                reserved.
            </footer>
        );
    }
}

export default Footer;
