
import React, {Component} from 'react'
import Auth from './../../service/Auth'
import {Redirect, NavLink} from 'react-router-dom'
import axios from 'axios'
import Server from './../../service/Server'
const BASEURL = Server.baseUrl;

class Login extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            password:'',
            error:false,
            disabled:false,
        }
        document.title = "Login";
    }
    
    login =(event) => {
        event.preventDefault();
        this.setState({
            disabled:true
        });
        axios.post(`${BASEURL}/login`,{
            username: this.state.username,
            password:this.state.password
        })
        .then(res => {
            var getRes = res.data;
            if(getRes.status === 'success') {
                localStorage.setItem('user', JSON.stringify(getRes.data));
                Auth.login(()=>{
                    this.props.login(true);
                })
                this.setState({
                    disabled:false
                });
            }
            else {
                    this.setState({
                        error:true,
                        disabled:false
                    })
            }
            
        })
        .catch(error=>{
            this.setState({
                error: true
            })
        })

    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        })
        
    }

    render() {
        if(Auth.isLogin === true) return <Redirect to='/dashboard'/>
        return(
                <div className="login-page">
                    <div className="login-box">
                        <div className="login-logo">
                            <NavLink to="/login"><b>File Management</b></NavLink>
                        </div>
                            <div className="login-box-body">
                                <p className="login-box-msg">Sign in to start your session</p>

                                <form  onSubmit={this.login}>
                                    <div className="form-group has-feedback">
                                        <input type="text" className="form-control" onChange={this.changeHandler} name="username" value={this.state.username} placeholder="Username"/>
                                        <span className="glyphicon glyphicon-user form-control-feedback"></span>
                                    </div>
                                    <div className="form-group has-feedback">
                                        <input type="password" className="form-control" onChange={this.changeHandler} name="password" value={this.state.password} placeholder="Password"/>
                                        <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                                    </div>
                                    {
                                        this.state.error ?
                                        <div className="alert alert-danger">
                                            <strong>Error!</strong> Username Or Password Invalid
                                        </div>
                                        :
                                        ""
                                    }
                                    
                                    <div className="form-group">
                                        <button type="submit" disabled={this.state.disabled}  className="btn btn-success btn-block ">{this.state.disabled ? 'Please Wait........' : 'Sign In'}</button>
                                    </div>
                                </form>
                                <div className='clearfix'></div>
                                    <NavLink to="/login">I forgot my password</NavLink><br></br>
                                </div>
                        </div>
                    </div>
            
            
        );
    }
}

export default Login;
