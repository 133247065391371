import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from '../../service/Server'
import { NavLink , withRouter  } from 'react-router-dom'
import ReactImageFallback from "react-image-fallback";
import Select from "../../Select";
import Auth from './../../service/Auth'

document.title = "Input File Content";
var fileDownload = require('js-file-download');

const BASEURL = Server.baseUrl;
const FileDir = BASEURL + "/getFile/";
class FileInfo extends Component {
    constructor(props) {
        super(props);
        this.myForm = React.createRef();
        this.state = {
            isLoaded: false,
            disabled:false,
            search_id: this.props.match.params.id,
            categories: [],
            fileList: [],
            fileInfo: [],
            permissionInfo: false,
            current_document_id:0,
            current_document_update_remarks:'',
            current_comment:'',
            step_array: [],
            current_document_name: 'Unknown Document',
            current_document_path: '',
            forms:[],
            new_step:"",
            total_step:1,
            form_title:"",
            new_comment:"",
            form_content:{},
            file_id:""
        };

    }

    getFileInfo = (callBack= () => {}) => {
        axios.get(`${BASEURL}/getProcessingFileInfo`, {
            params: {
                id: this.state.search_id
            }
        })
        .then(res => {
            var getRes = res.data;
            let totalStep = getRes.data.total_step;
            let step_array = [];
            for(let i=1; i <= totalStep;i++) {
                step_array.push({
                    step_id: i,
                    name: 'Step-' + i,
                    label:'Step-' + i,
                    value:i
                });
            }
            this.setState({
                isLoaded: true,
                fileInfo: getRes.data,
                new_step: getRes.data.step_status,
                permissionInfo: getRes.data.permissionInfo,
                total_step: getRes.data.total_step,
                step_array: step_array
            },()=>{
                callBack();
            });
        })
    }

    componentDidMount() {
        axios.get(`${BASEURL}/getCategories`)
            .then(res => {
                var category = res.data;
                this.setState({
                    categories: category.data
                }, () => {
                    this.getFileInfo();
                });
            })
    }

    componentWillReceiveProps(newProps) {
        
        this.setState({
            search_id: newProps.match.params.id,
        },()=>{
            this.getFileList();
        });
    }

    dropDownUpdate = (id) => {
        // let step_array = this.state.step_array;
        // step_array[index].user_id = id;
        this.setState({
            new_step: id
        })
    }

    contentUpdate = (index) => {

        let fileInfo = this.state.fileList[index];
        // console.log(fileInfo.category_id);

        axios.get(`${BASEURL}/getCategories?id=`+fileInfo.category_id)
            .then(res => {
                var category = res.data.data;
                console.log(category);
                // let category = this.state.categories.filter((item)=>item.id===fileInfo.category_id);
                // let category = category.data.properties;
                // console.log(this.state.categories);
                // return false;
                let properties = JSON.parse(category.properties);
                let content = {};

                if(fileInfo.status > 1) {
                    let file_content = JSON.parse(fileInfo.content);
                    properties.forEach((item)=>{
                        content[item.name] = file_content.hasOwnProperty(item.name) ? file_content[item.name] : [];
                    });
                }
                else {
                    properties.forEach((item)=>{
                        content[item.name] = [];
                    });
                }

                this.setState({
                    forms: properties,
                    form_title:category.title,
                    form_content:content,
                    file_id:fileInfo.id
                },()=>{
                    // console.log(this.state);
                });
            })


    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true
        });
        
        axios.post(`${BASEURL}/inputFileData`,{
            content: this.state.form_content,
            id:this.state.file_id
        })
        .then(res => {

            let getRes = res.data;
            
            if(getRes.status==='success') {
                this.setState({
                    disabled: false
                },()=>{
                    this.modalClose();
                    this.getFileList(()=>{
                        Swal.fire(
                            'Saved!',
                            'File Content Save Successfully.',
                            'success'
                        )
                    });
                });
                
            }
            else {
                this.setState({
                    disabled: false
                });
                Swal.fire(
                    'Error!',
                    'something is wrong please try again',
                    'error'
                )
            }
        }) 
    }
    submitStatus = () => {
        // event.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be change step status!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {

            if (result.value) {
                axios.post(`${BASEURL}/saveFileStatus`,{
                    step_status: this.state.new_step,
                    total_step: this.state.total_step,
                    id:this.state.fileInfo.id
                })
                    .then(res => {

                        let getRes = res.data;

                        if(getRes.status==='success') {
                            this.setState({
                                disabled: false
                            },()=>{
                                this.modalClose();
                                this.getFileInfo(()=>{
                                    Swal.fire(
                                        'Saved!',
                                        'File Step Change Successfully.',
                                        'success'
                                    )
                                });
                            });

                        }
                        else {
                            this.setState({
                                disabled: false
                            });
                            Swal.fire(
                                'Error!',
                                'something is wrong please try again',
                                'error'
                            )
                        }
                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }
            else {
                Swal.fire(
                    'Cancelled!',
                    'Status change is cancel',
                    'error'
                )
            }

        });
    }


    submitArchiveStatus = () => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be archive !",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, archive it!'
        }).then((result) => {

            if (result.value) {
                axios.post(`${BASEURL}/saveFileArchiveStatus`,{
                    // step_status: this.state.new_step,
                    // total_step: this.state.total_step,
                    id:this.state.fileInfo.id
                })
                    .then(res => {

                        let getRes = res.data;

                        if(getRes.status==='success') {
                            this.setState({
                                disabled: false
                            },()=>{
                                this.modalClose();
                                this.getFileInfo(()=>{
                                    Swal.fire(
                                        'Saved!',
                                        'New File Archived Successfully.',
                                        'success'
                                    )
                                });
                            });

                        }
                        else {
                            this.setState({
                                disabled: false
                            });
                            Swal.fire(
                                'Error!',
                                'something is wrong please try again',
                                'error'
                            )
                        }
                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }
            else {
                Swal.fire(
                    'Cancelled!',
                    'Status archiving is cancel',
                    'error'
                )
            }

        });
    }




    changeHandler = (event) => {
        let content = this.state.form_content;
        // console.log(content);
        if(event.target.type === "checkbox") {
            if(event.target.checked){
                content[event.target.name].push(event.target.value);
                this.setState({
                    form_content : content
                },() => {
                    console.log(this.state.form_content);
                })
            }
            else {
                let items = content[event.target.name].filter(item => item !== event.target.value);
                content[event.target.name] = items;
                this.setState({
                    form_content : content
                },()=>{
                    console.log(this.state.form_content);
                })
            }
        }
        else if(event.target.type === "file") {
            var newFile = {
                file: event.target.files[0].name,
                image: '',
                src: URL.createObjectURL(event.target.files[0])
            };

            let files = event.target.files;
            if (!files.length)
                return;
            let reader = new FileReader();
            reader.onload = (e) => {
                newFile.image = e.target.result;
            };
            reader.readAsDataURL(files[0]);
            let keyName = event.target.name;
            setTimeout(() => {
                content[keyName][0]=newFile.image;
                this.setState({
                    form_content : content
                },()=>{
                    console.log(this.state.form_content);
                });
            }, 3000);
        }
        else {
            content[event.target.name][0] = event.target.value; 
            this.setState({
                form_content : content
            },()=>{
                console.log(this.state.form_content);
            });
        }
    }


    getCategoryName = (category_id) => {
        let category = this.state.categories.filter(function (item) {
            return item.category_id === category_id
        });

        if (category.length > 0) {
            return "category[0].title";
        }
        else {
            return "Unknown Category";
        }
    }

    checkPermission = () => {
        let permissionInfo = this.state.permissionInfo;
        let currentStatus = this.state.fileInfo.step_status;
        if(permissionInfo) {
            let processSettingsInfo = permissionInfo.find(x => x.step_id == currentStatus);
            if(processSettingsInfo) {
                return processSettingsInfo;
            }
            return false;
        }
        else {
            return false;
        }
    }


    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    documentUpdatemodalClose = () => {
        var el = document.getElementById('closedocumentupdateBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    displayFile = (id) => {
        let processSettingsInfo = this.state.fileInfo.documents.find(x => x.id == id);
        // console.log(processSettingsInfo);
        // axios.get(`${BASEURL}/displayFile?id=`+id)
        //     .then(res => {
        //         var fileInfo = res.data;
        //         this.setState({
        //             attachment_path:fileInfo.attachment_path,
        //             file_details:fileInfo.data
        //         });
        //         console.log(fileInfo);
        //     });
        this.setState({
            current_document_name : processSettingsInfo.document_name,
            current_document_path : processSettingsInfo.file_path
        });
    };

    displayUploadForm = (id) => {
        let processSettingsInfo = this.state.fileInfo.documents.find(x => x.id == id);
        // console.log(processSettingsInfo);
        // axios.get(`${BASEURL}/displayFile?id=`+id)
        //     .then(res => {
        //         var fileInfo = res.data;
        //         this.setState({
        //             attachment_path:fileInfo.attachment_path,
        //             file_details:fileInfo.data
        //         });
        //         console.log(fileInfo);
        //     });
        this.setState({
            current_document_id : processSettingsInfo.id,
            current_document_name : processSettingsInfo.document_name,
            current_document_path : processSettingsInfo.file_path
        });
    };

    getDownload = (path, fileName) => {
        fileDownload(path, fileName);
        // axios.get(`${BASEURL}/downloadFile?id=`+id)
        //     .then(res => {
        //         var getRes = res.data;
        //         fileDownload(getRes.data, fileName);
        //     })
    }

    changeHandler = (index,event) => {

        // let tempDocumentList = this.state.documentList;
        // let content = this.state.documentList[index];

        if(event.target.type === "checkbox") {
        }
        else if(event.target.type === "file") {
            var newFile = {
                file: event.target.files[0].name,
                image: '',
                src: URL.createObjectURL(event.target.files[0])
            };

            let files = event.target.files;
            if (!files.length)
                return;
            let reader = new FileReader();
            reader.onload = (e) => {
                newFile.image = e.target.result;
            };
            reader.readAsDataURL(files[0]);
            let keyName = event.target.name;
            setTimeout(() => {
                // content.image_path =newFile.image;
                // tempDocumentList[index] = content;
                this.setState({
                    current_document_path : newFile.image
                });
            }, 3000);
        }
        else {
            // content[event.target.name][0] = event.target.value;
            this.setState({
                [event.target.name] : event.target.value
            },()=>{
                console.log(this.state.documentList);
            });
        }
    }

    updateDocument = (event) => {
        event.preventDefault();
        // console.log(this.state.documentList);
        // return false;
        this.setState({
            disabled: true
        });
        var data = {
            id:this.state.current_document_id,
            file_path:this.state.current_document_path,
            remarks:this.state.current_document_update_remarks
        };

        axios.post(`${BASEURL}/processFileUpdate`,data)
            .then(res => {
                let getRes = res.data;
                if(getRes.status==='success') {
                    this.setState({
                        disabled: false
                    },()=>{
                        this.documentUpdatemodalClose();
                        this.getFileInfo(()=>{
                            Swal.fire(
                                'Updated!',
                                'File Document Updated Successfully',
                                'success'
                            )
                        });
                    });

                }
                else {
                    this.setState({
                        disabled: false
                    });
                    Swal.fire(
                        'Error!',
                        'something is wrong please try again',
                        'error'
                    )
                }
            })
    };


    addComment = (event) => {
        event.preventDefault();
        // console.log(this.state.documentList);
        // return false;
        this.setState({
            disabled: true
        });
        var data = {
            process_archive_info_id:this.state.fileInfo.id,
            comment:this.state.current_comment,
        };
        // console.log(data);
        // return;
        axios.post(`${BASEURL}/addFileComment`,data)
            .then(res => {
                let getRes = res.data;
                if(getRes.status==='success') {
                    this.setState({
                        disabled: false,
                        current_comment:''
                    },()=>{
                        this.documentUpdatemodalClose();
                        this.getFileInfo(()=>{
                            Swal.fire(
                                'Added!',
                                'File Comment Added Successfully',
                                'success'
                            )
                        });
                    });

                }
                else {
                    this.setState({
                        disabled: false
                    });
                    Swal.fire(
                        'Error!',
                        'something is wrong please try again',
                        'error'
                    )
                }
            })
    };



    render() {
        const documents = this.state.fileInfo.documents ? this.state.fileInfo.documents : [];
        const comments = this.state.fileInfo.comments ? this.state.fileInfo.comments : [];
        const stepsData = this.state.step_array ? this.state.step_array : [];
        const permission = this.checkPermission();
        console.log('permission-',permission);
        return (
            <div className="content-wrapper">
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                            <div className="row">
                                <div className="col-lg-12 col-xs-12 col-md-12">
                                    <div className="box box-default">
                                        <div className="box-header with-border">
                                            <h1 style={{fontSize:"30px"}} className="box-title">{this.state.fileInfo.process_file_name} -Details Information</h1>
                                        </div>
                                        <div className="box-body" style={{ minHeight: "300px" }}>
                                            <div className="row">
                                                <div className="col-md-7 col-sm-7 col-lg-7">
                                                    <table className="table table-bordered">
                                                        <tr>
                                                            <th colSpan={6} style={{textDecoration:'underline'}}><h4>File Basic Info</h4></th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:"bold"}}>File Name</td>
                                                            <td>{this.state.fileInfo.process_file_name}</td>
                                                            <td style={{fontWeight:"bold"}}>Tracking ID</td>
                                                            <td>{this.state.fileInfo.tracking_id}</td>
                                                            <td style={{fontWeight:"bold"}}>Created By</td>
                                                            <td>{this.state.fileInfo.created_by_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:"bold"}}>Created Time</td>
                                                            <td>{this.state.fileInfo.created_time}</td>
                                                            <td style={{fontWeight:"bold"}}>Total Document</td>
                                                            <td>{this.state.fileInfo.total_document}</td>
                                                            <td style={{fontWeight:"bold"}}>Last Update By</td>
                                                            <td>{this.state.fileInfo.updated_by_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:"bold"}}>Updated Time</td>
                                                            <td>{this.state.fileInfo.updated_time}</td>
                                                            <td style={{fontWeight:"bold"}}>Status</td>
                                                            <td>
                                                                <span className="label label-default">{this.state.fileInfo.archive_status=='yes' ? 'Archived': (this.state.fileInfo.archive_status=='temp_delete' ? 'Trashed' : 'Processing') }</span>
                                                            </td>
                                                            <td style={{fontWeight:"bold"}}>Current Status</td>
                                                            <td>
                                                                <span className="label label-default"> Step-{this.state.fileInfo.step_status}</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:"bold"}}>Remarks</td>
                                                            <td colSpan="5">{this.state.fileInfo.remarks}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="col-md-5 col-lg-5 col-md-5">
                                                    <table className="table table-bordered">
                                                        <tr>
                                                            <th colSpan="2"  style={{textDecoration:'underline'}}><h4>Change Status</h4></th>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Select
                                                                onChange={(e)=>{this.dropDownUpdate(e.value)}}
                                                                options={stepsData}
                                                                placeholder="Select Step"
                                                                value={this.state.new_step} />
                                                            </td>
                                                            <td>
                                                                &nbsp;&nbsp;  &nbsp;&nbsp;
                                                                {
                                                                    this.state.fileInfo.archive_status=='no' && ( Auth.isAdmin() || permission !== false ) ? (
                                                                        <button type="button" disabled={this.state.disabled} onClick={()=>{ this.submitStatus() }} className="btn btn-md btn-primary">{this.state.disabled ? 'Saveing': 'Submit'}</button>

                                                                    ) : ''
                                                                }
                                                                &nbsp;&nbsp;
                                                                {
                                                                    (this.state.fileInfo.step_status==this.state.total_step && this.state.fileInfo.archive_status=='no' && ( Auth.isAdmin() || permission !== false ) ?
                                                                        <button type="button" disabled={this.state.disabled} onClick={()=>{ this.submitArchiveStatus() }} className="btn btn-md btn-success">{this.state.disabled ? 'Saveing': 'Archive'}</button>
                                                                        : '')
                                                                }
                                                                &nbsp;&nbsp;
                                                                <NavLink to="/process_file_list">
                                                                    <button type="button" className="btn btn-md btn-danger"><i className="fa fa-backward"></i> Go Back</button>
                                                                </NavLink>

                                                                {/*<button type="button" disabled={this.state.disabled} onClick={()=>{ this.submitDeleteStatus() }} className="btn btn-md btn-danger">{this.state.disabled ? 'Saveing': 'Delete'}</button>*/}
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <h4 style={{textDecoration:'underline'}}>Attachment Info</h4>
                                                </div>

                                                {
                                                    documents.length > 0 ? (
                                                        documents.map((item, index) =>
                                                            <div className="col-md-3 col-lg-3" key={index}>
                                                                <div className="panel panel-info">
                                                                    <div className="panel-heading">
                                                                        <b>{ item.document_name }</b>
                                                                    </div>
                                                                    <div className="panel-body">
                                                                        <ReactImageFallback
                                                                            src={item.file_path}
                                                                            fallbackImage={'dist/img/file_default.png'}
                                                                            initialImage={'dist/img/fallback.gif'}
                                                                            alt="cool image should be here"
                                                                            style={{height: "100px", width: "100%"}}
                                                                            className="img-rounded responsive" />
                                                                    </div>
                                                                    <div className="panel-footer">
                                                                        <button type="button" data-toggle="modal" data-target="#modal-default1" onClick={() => this.displayFile(item.id)} className="btn btn-info btn-xs"><i
                                                                            className="fa fa-eye"></i> View
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <button type="button"  onClick={() => this.getDownload(item.file_path,item.file_name)} className="btn btn-success btn-xs"><i
                                                                            className="fa fa-download"></i> Download
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <button type="button" data-toggle="modal" data-target="#modal-default2" onClick={() => this.displayUploadForm(item.id)} className="btn btn-primary btn-xs"><i
                                                                            className="fa fa-upload"></i> Edit
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        {/*<button type="button" data-toggle="modal" data-target="#modal-default2" onClick={() => this.logHistory(item.id)} className="btn btn-danger btn-xs"><i*/}
                                                                        {/*    className="fa fa-trash"></i> Delete*/}
                                                                        {/*</button>*/}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    ) : ''

                                                }

                                            </div>

                                            <div className="row">
                                                {/*<div className="col-md-12 col-lg-12">*/}
                                                {/*    <h3 style={{textDecoration:'underline'}}>Processing Comments</h3>*/}
                                                {/*</div>*/}
                                                <div className="col-md-6 col-md-6 col-lg-6">
                                                    <form className="form-horizontal"  onSubmit={this.addComment}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <td colSpan="3"><h4 style={{textDecoration:'underline'}}>Processing Comments</h4></td>
                                                            </tr>
                                                            <tr>
                                                                <th>UserInfo</th>
                                                                <th>Comment</th>
                                                                <th>Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            comments.length > 0 ? (
                                                                comments.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>{item.name}({item.created_by})</td>
                                                                        <td style={{"fontWeight":'bold'}}>{item.comments}</td>
                                                                        <td>{item.created_time}</td>
                                                                    </tr>
                                                                )
                                                            ) : ''

                                                        }
                                                            <tr>
                                                                <td colSpan="3">
                                                                    <textarea required className="form-control" onChange={(e) => {this.changeHandler(0, e)}} name="current_comment" value={this.state.current_comment} placeholder="Add New Comment"></textarea>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="3">
                                                                    <button type="submit" disabled={this.state.disabled} className="btn btn-primary btn-md"><i className="fa fa-comment"></i> Add Comment</button>
                                                                </td>
                                                             </tr>
                                                        </tfoot>
                                                    </table>
                                                    </form>
                                                </div>
                                                <div className="col-md-6 col-md-6 col-lg-6">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <td colSpan="3"><h3 style={{textDecoration:'underline'}}>Log History</h3></td>
                                                            </tr>
                                                            <tr>
                                                                <th>UserInfo</th>
                                                                <th>LogInfo</th>
                                                                <th>Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer">
                                            {/*<div className="row">*/}
                                            {/*    <div className="col-md-1 col-lg-1">*/}
                                            {/*        <b>Change Status</b>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-md-3 col-lg-3">*/}
                                            {/*        <Select*/}
                                            {/*            onChange={(e)=>{this.dropDownUpdate(e.value)}}*/}
                                            {/*            options={stepsData}*/}
                                            {/*            placeholder="Select Step"*/}
                                            {/*            value={this.state.new_step} />*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <br/>
                                            <div className="row">
                                                {/*<div className="col-md-3 col-sm-3">*/}
                                                {/*    <button type="button" disabled={this.state.disabled} onClick={()=>{ this.callNext() }} className="btn btn-md btn-primary">{this.state.disabled ? 'Saveing': 'Save'}</button>*/}
                                                {/*    &nbsp;&nbsp;*/}
                                                {/*    <NavLink to="/process_file_list">*/}
                                                {/*        <button type="button" className="btn btn-md btn-danger"><i className="fa fa-backward"></i> Back</button>*/}
                                                {/*    </NavLink>*/}
                                                {/*</div>*/}
                                                {/*<div className="col-md-4 col-sm-4">*/}

                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                    </Loader>
                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">

                            <form  onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">{this.state.form_title}</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            {
                                                this.state.forms.map((item, index) =>
                                                    <div key={index} className="form-group">
                                                        <label htmlFor="name">{item.label}</label>
                                                        {
                                                            item.attributes.name === "select" ? 
                                                            <select onChange={this.changeHandler} value={this.state.form_content[item.name][0]} name={item.name} className="form-control">
                                                                <option value="">Select Gender</option>
                                                                {
                                                                    item.attributes.hasoption===true && item.attributes.options.length > 0 ?
                                                                    item.attributes.options.map((option,optionKey) => 
                                                                        <option key={optionKey} value={option.value}>{option.name}</option>
                                                                    )
                                                                    :
                                                                    ""
                                                                }
                                                            </select>
                                                            :
                                                            (
                                                            item.attributes.name === "textarea" ? 
                                                            <textarea onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name}></textarea>
                                                            :
                                                            (
                                                            item.attributes.name === "checkbox" ? 
                                                            <div>
                                                                {
                                                                    item.attributes.options.map((option,optionKey)=>
                                                                        <div key={optionKey}>
                                                                            <span>{option.name}</span> <input type={item.attributes.name} name={item.name} checked={this.state.form_content[item.name].indexOf(option.value) >= 0  ? true: false } value={option.value} onChange={this.changeHandler}/>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            :
                                                                (
                                                                        item.attributes.name=='file' ?
                                                                            (
                                                                                <span>
                                                                                    <ReactImageFallback
                                                                                        src={this.state.form_content[item.name][0]}
                                                                                        fallbackImage={'dist/img/file_default.png'}
                                                                                        initialImage={'dist/img/fallback.gif'}
                                                                                        alt="cool image should be here"
                                                                                        style={{height: "100px", width: "100%"}}
                                                                                        className="img-rounded responsive" />
                                                                                    <input type={item.attributes.name} onChange={this.changeHandler} className="form-control" value={''} name={item.name} placeholder="Enter Full Name" />
                                                                                </span>

                                                                            )

                                                                    :
                                                                    <input type={item.attributes.name} onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name} placeholder="Enter Full Name" />
                                                                )


                                                            )
                                                            )
                                                        }
                                                        
                                                    </div>
                                                )
                                            }

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.disabled} className="btn btn-primary">{this.state.disabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>

                    <div className="modal fade" id="modal-default1">

                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h3 className="modal-title">{this.state.current_document_name}</h3>
                                </div>

                                <div className="modal-body">
                                    <ReactImageFallback
                                        src={this.state.current_document_path}
                                        fallbackImage={'dist/img/file_default.png'}
                                        initialImage={'dist/img/fallback.gif'}
                                        alt="cool image should be here"
                                        style={{float:"left",height: "300px", width: "100%"}}
                                        className="img-rounded responsive" />
                                </div>

                                <div className="modal-footer">
                                    <br/>
                                    <button type="button" className="btn btn-danger pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="modal fade" id="modal-default2">

                        <div className="modal-dialog">
                            <div className="modal-content">
                                <form className="form-horizontal"  onSubmit={this.updateDocument}>
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h3 className="modal-title">Edit-{this.state.current_document_name}</h3>
                                </div>

                                <div className="modal-body">
                                    <table className="table table-bordered">
                                        <tr>
                                            <td>
                                                <ReactImageFallback
                                                    src={this.state.current_document_path}
                                                    fallbackImage={'dist/img/file_default.png'}
                                                    initialImage={'dist/img/fallback.gif'}
                                                    alt="cool image should be here"
                                                    style={{float:"left",height: "100px", width: "40%"}}
                                                    className="img-rounded responsive" />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <input type="file" onChange={(e) => {this.changeHandler(0, e)}}
                                                       className="form-control" value={''}
                                                       name={this.state.current_document_path}
                                                       placeholder="Enter Full Name"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                               Edit Remarks
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <textarea required className="form-control" onChange={(e) => {this.changeHandler(0, e)}}  name='current_document_update_remarks' value={this.state.current_document_update_remarks} placeholder={'Enter Remarks'}></textarea>
                                            </td>
                                        </tr>

                                    </table>

                                </div>

                                <div className="modal-footer">
                                    <br/>
                                    <button type="button" className="btn btn-danger pull-left" id="closedocumentupdateBtn" data-dismiss="modal">Close</button>
                                    <button type="submit" disabled={this.state.disabled} className="btn btn-primary btn-md">{this.state.disabled?'Uploading' : 'Upload'}</button>
                                </div>
                                </form>

                            </div>

                        </div>

                    </div>

                </section>
            </div>
        );
    }
}

export default withRouter(FileInfo);
