import React from 'react';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader';
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        document.title = 'Dashboard'
    }
    componentDidMount() {
        this.setState({
            loaded: true
        });
    }
    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Dashboard
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><NavLink to="/"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                        <li className="active">Dashboard</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader  loaded={this.state.loaded}>
                        <div className="row">
                            <div className="col-xs-12 col-lg-12 col-md-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Dashboard</h3>
                                    </div>
                                    <div className="box-body">
                                        <h1>Dashboard Content</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Loader>
                </section>
            </div>
        )
    }
}

export default Dashboard;