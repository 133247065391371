
import React, { Component } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader';
import Server from './../../service/Server'
const BASEURL = Server.baseUrl;
document.title = "File List";

class ManageFile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            isLoaded: false
        }
    }

    getFileList = (event) => {
        axios.get(`${BASEURL}/getScaningFile`)
            .then(res => {
                var getRes = res.data;
                this.setState({
                    fileList: getRes.data,
                    isLoaded: true
                });
            })
    }

    componentDidMount() {
        this.getFileList();
    }

    render() {

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Scan File
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-archive"></i> Archive</li>
                        <li className="active">Scan File</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>

                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">File List</h3>
                                    </div>
                                    <div className="box-body">
                                        {
                                            this.state.fileList.length > 0
                                                ?
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>SL</th>
                                                            <th>Code</th>
                                                            <th>Total File</th>
                                                            <th>Scan Complete</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {this.state.fileList.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{item.code}</td>
                                                                <td>{item.total_files}</td>
                                                                <td>{item.scan_complete}</td>
                                                                <td>
                                                                    <NavLink to={"/scan_file/"+item.code}>
                                                                        <button type="button" className="btn btn-info btn-sm"><i className="fa fa-search"></i> Scan</button>
                                                                    </NavLink>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                                :
                                                <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Loader>
                </section>
            </div>

        );
    }
}

export default ManageFile;
