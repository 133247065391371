
import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from './../../service/Server'

const BASEURL = Server.baseUrl;

const intitialState = {
    name: '',
    sorting: '',
    id: '',
    isLoaded: false,
    parent_id: 0,
    error: false,
    errorMessage: {},
    departments: [],
    isBtnDisabled: false,
};

class Department extends Component {

    constructor(props) {
        super(props)
        this.myForm = React.createRef()
        this.state = intitialState;
        document.title = "Department";
    }

    openModal = (parent_id) => {
        this.setState({
            name: '',
            sorting: '',
            id: '',
            parent_id: parent_id,
            error: false,
        });
    }

    getDepartments = (callBack = () => { }) => {
        axios.get(`${BASEURL}/getDepartments`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    departments: getRes.data,
                    isLoaded: true,
                }, () => {
                    callBack();
                })
            })
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getDepartments();
    }


    update = (index) => {
        axios.get(`${BASEURL}/getDepartments`, {
            params: {
                id: index
            }
        })
            .then(response => {
                var getRes = response.data;
                var department = getRes.data;
                this.setState({
                    name: department.name,
                    sorting: department.sorting,
                    id: department.id,
                    parent_id: department.parent_id,
                    error: false,
                });

            })
            .catch(error => console.log(error));

    }

    delete = (id) => {
        var departmentId = id;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {

                axios.post(`${BASEURL}/departmentDelete`, { id: departmentId })
                    .then(res => {
                        var getRes = res.data;
                        if (getRes.status === 'success') {
                            this.setState({
                                isLoaded: false
                            });
                            this.getDepartments(() => {
                                Swal.fire(
                                    'Deleted!',
                                    'Department Deleted Successfully',
                                    'success'
                                )
                            });


                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                'Department delete is fail',
                                'warning'
                            )
                        }


                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }

            else {
                Swal.fire(
                    'Cancelled!',
                    'Department delete is cancel',
                    'error'
                )
            }
        })

    }

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    submitHandler = (event) => {

        event.preventDefault();

        var departmentData = {
            "name": this.state.name,
            "parent_id": this.state.parent_id,
            "sorting": this.state.sorting,
            "id": this.state.id
        };

        this.setState({
            isBtnDisabled: true,
        });

        if (this.state.id === '') {
            axios.post(`${BASEURL}/departmentCreate`, departmentData)
                .then(res => {
                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            name: '',
                            sorting: '',
                            id: '',
                            id: 0,
                            isLoaded: false,
                            error: false,
                            errorMessage: {},
                            departments: [],
                            isBtnDisabled: false
                        });

                        this.getDepartments(() => {
                            Swal.fire(
                                'Created!',
                                'Department Created Successfully',
                                'success'
                            )
                        });
                        this.modalClose();

                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }
        else {
            axios.post(`${BASEURL}/departmentUpdate`, departmentData)
                .then(res => {

                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            name: '',
                            sorting: '',
                            id: '',
                            parent_id: 0,
                            isLoaded: false,
                            error: false,
                            errorMessage: {},
                            departments: [],
                            isBtnDisabled: false
                        });

                        this.getDepartments(() => {
                            Swal.fire(
                                'Updated!',
                                'Department Updated Successfully',
                                'success'
                            )
                        });
                        this.modalClose();

                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false,
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }
    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

    }

    renderChild = (child) => {
        if (child.sub.length > 0) {
            return (
                <li>
                    {child.name}  &nbsp;&nbsp;[<i title="Add Subdepartment" data-toggle="modal" data-target="#modal-default" style={{cursor:"pointer"}} className="fa fa-plus" onClick={(e) => {this.openModal(child.id)}}></i>] [<i title="Edit Category" style={{cursor:"pointer"}} data-toggle="modal" data-target="#modal-default" className="fa fa-pencil" onClick={(e) => { this.update(child.id) }}></i>] [<i title="Remove Category" style={{cursor:"pointer"}} className="fa fa-remove" onClick={(e) => {this.delete(child.id)}}></i>]
                    <ul style={{lineHeight:"20px"}}>
                        {child.sub.map((item,index) => {
                            return this.renderChild(item);
                        })}
                    </ul>
                </li>
            );
        }
        else if (child.name) {
            return (
                <li>
                    {child.name}
                    &nbsp;&nbsp;[<i title="Add Subdepartment" data-toggle="modal" data-target="#modal-default" style={{cursor:"pointer"}} className="fa fa-plus" onClick={(e) => {this.openModal(child.id)}}></i>] [<i title="Edit Category" style={{cursor:"pointer"}} data-toggle="modal" data-target="#modal-default" className="fa fa-pencil" onClick={(e) => { this.update(child.id) }}></i>] [<i title="Remove Category" style={{cursor:"pointer"}} className="fa fa-remove" onClick={(e) => {this.delete(child.id)}}></i>]
                </li>
            );
        }
        return null;
    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Department
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-cogs"></i> Configuration</li>
                        <li className="active">Department</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Department List</h3>
                                        <button onClick={(e)=>{this.openModal(0)}} style={{ float: "right" }} type="button" className="btn btn-primary btn-md" data-toggle="modal" data-target="#modal-default">
                                            <i className="fa fa-plus"></i> New Department
                                        </button>
                                    </div>
                                    <div className="box-body">
                                        <table style={{ width: "100%" }} className="table table-bordered table-hover">
                                            {/*<thead>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>SL</th>*/}
                                            {/*        <th>Name</th>*/}
                                            {/*        <th>Sorting</th>*/}
                                            {/*        <th>Action</th>*/}
                                            {/*    </tr>*/}
                                            {/*</thead>*/}
                                            <tbody>
                                                {/*{this.state.departments.map((item, index) =>*/}
                                                {/*    <tr key={index}>*/}
                                                {/*        <td>{index + 1}</td>*/}
                                                {/*        <td>{item.name}</td>*/}
                                                {/*        <td>{item.sorting}</td>*/}
                                                {/*        <td>*/}
                                                {/*            <button data-toggle="modal" data-target="#modal-default" onClick={() => { this.update(index) }} className="btn btn-info btn-sm"><i className="fa fa-pencil"></i> Update</button>*/}
                                                {/*            &nbsp;*/}
                                                {/*            <button onClick={() => { this.delete(item.id) }} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i> Delete</button>*/}
                                                {/*        </td>*/}
                                                {/*    </tr>*/}
                                                {/*)}*/}
                                                {
                                                    this.state.departments.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <ul style={{lineHeight:"20px"}}>
                                                                    {this.renderChild(item)}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Loader>

                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">

                            <form ref={this.myForm} role="form" onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">Department</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            <div className="form-group">
                                                <label htmlFor="name">Department Name</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.name} name="name" placeholder="Enter Department Name" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="sorting">Sorting</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.sorting} name="sorting" placeholder="Enter Sorting" />
                                            </div>

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">{this.state.isBtnDisabled ? 'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>


                </section>
            </div>
        );

    }
}

export default Department;
