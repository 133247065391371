
import React from 'react';
import Category from './../componnent/archive/Category';
import CodeGenerator from './../componnent/archive/CodeGenerator';
import Report from './../componnent/archive/Report';
import FileEntry from './../componnent/archive/FileEntry';
import ManageFile from './../componnent/archive/ManageFile';
import FileList from './../componnent/archive/FileList';
import ProcessFileList from './../componnent/archive/ProcessFileList';
import ProcessTempFileList from './../componnent/archive/ProcessTempFileList';
import TrashList from './../componnent/archive/TrashList';
import ScanFile from './../componnent/archive/ScanFile';
import FileContent from './../componnent/archive/FileContent';
import FileInfo from './../componnent/archive/FileInfo';
import InputContent from './../componnent/archive/InputContent';
import PrivateRoute from './../componnent/auth/PrivateRoute';
import { Switch } from 'react-router-dom';
import ProcessReport from "../componnent/archive/ProcessReport";
class Archive  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        }
    }

    render() {
        return (
            <Switch>
                
                <PrivateRoute exact path="/codeGenerator" component={CodeGenerator}/>
                    
                <PrivateRoute exact path="/fileEntry" component={()=>
                    <FileEntry/>
                }/>

                <PrivateRoute exact path="/manageFile" component={ () => 
                    <ManageFile/>
                }/>

                <PrivateRoute exact path="/input_content/:id" component={ () => 
                    <InputContent/>
                }/>
                
                <PrivateRoute exact path="/scan_file/:id" component={ScanFile}/>
                <PrivateRoute exact path="/file_content" component={FileContent}/>
                

                <PrivateRoute exact path="/category" component={Category}/>

                <PrivateRoute exact path="/report" component={Report}/>
                <PrivateRoute exact path="/process_report" component={ProcessReport}/>
                <PrivateRoute exact path="/file_list" component={FileList}/>
                <PrivateRoute exact path="/process_file_list" component={ProcessFileList}/>
                <PrivateRoute exact path="/process_temp_file_list" component={ProcessTempFileList}/>
                <PrivateRoute exact path="/file_details/:id" component={ () =>
                    <FileInfo/>
                }/>
                <PrivateRoute exact path="/trash_list" component={TrashList}/>





            </Switch>
        );
    }
}

export default Archive;
