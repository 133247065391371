import React, {Component} from 'react';
import Auth from './../../service/Auth';
import axios from 'axios';
import Swal from 'sweetalert2';
import Server from './../../service/Server';
import Loader from "react-loader";
import parse from 'html-react-parser';
import ReactImageFallback from "react-image-fallback";
import Select from "../../Select";
import {NavLink} from "react-router-dom";
const BASEURL = Server.baseUrl;


const FileDir = BASEURL + "/getFile/";

var fileDownload = require('js-file-download');

class ProcessFileList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: '',
            title: '',
            category_id: '',
            remarks: '',
            process_file_settings_id: '',
            status: 2,
            process_status: 1,
            loaded: 0,
            isBtnDisabled: false,
            isLoaded: false,
            errorMessage: {},
            error: false,
             permission_info: false,
            fileList: [],
            forms: [],
            form_title:"",
            form_content:{},
            categories: [],
            processSettingsList: [],
            documentList: [],
            content_code:'',
            attachment_path:'',
            file_details:{},
            input_type:2,
            level_id:0,
            archive_dropdown:false,
            logHistory:[],
            isHistoryLoaded:false
        };
    }

    getDownload = (id, fileName) => {
        axios.get(`${BASEURL}/downloadFile?id=`+id)
            .then(res => {
                var getRes = res.data;
                fileDownload(getRes.data, fileName);
            })
    }
    getFileList = (callBack= ()=> {}) => {
        axios.get(`${BASEURL}/getProcessingFileList`)
        .then(res => {
            var getRes = res.data;
            // console.log(getRes);
            this.setState({
                fileList: getRes.data,
                isLoaded: true,
                // level_id: getRes.level_id,
                // archive_dropdown: getRes.level_id == 0 ? true:false,
                // attachment_path: getRes.attachment_path
            },()=>{
                callBack();
            });
        })
    }

    componentDidMount() {
        axios.get(`${BASEURL}/getArchiveSettingsList`)
            .then(res => {
                var category = res.data;
                // return;
                this.setState({
                    processSettingsList: category.data
                }, () => {
                    this.getFileList();
                });
            })
    }

    displayFile = (id) => {
        axios.get(`${BASEURL}/displayFile?id=`+id)
            .then(res => {
                var fileInfo = res.data;
                this.setState({
                    attachment_path:fileInfo.attachment_path,
                    file_details:fileInfo.data
                });
                console.log(fileInfo);
            });
    };

    getCategoryName = (category_id) => {
        let category = this.state.categories.filter(function (item) {
            return item.value === category_id
        });

        if (category.length > 0) {
            return category[0].label;
        }
        else {
            return "Unknown Category";
        }
    }

    contentUpdate = (category_id) => {
        let processSettingsInfo = this.state.processSettingsList.find(x => x.id == category_id);
        this.setState({
            documentList:processSettingsInfo.files,
            process_file_settings_id:category_id,
        },()=>{
            console.log(this.state);
        });
    }

    changeHandler = (index,event) => {

        let tempDocumentList = this.state.documentList;
        let content = this.state.documentList[index];

        if(event.target.type === "checkbox") {
            // if(event.target.checked){
            //     content[event.target.name].push(event.target.value);
            //     this.setState({
            //         form_content : content
            //     },() => {
            //         console.log(this.state.form_content);
            //     })
            // }
            // else {
            //     let items = content[event.target.name].filter(item => item !== event.target.value);
            //     content[event.target.name] = items;
            //     this.setState({
            //         form_content : content
            //     },()=>{
            //         console.log(this.state.form_content);
            //     })
            // }
        }
        else if(event.target.type === "file") {
            var newFile = {
                file: event.target.files[0].name,
                image: '',
                src: URL.createObjectURL(event.target.files[0])
            };

            let files = event.target.files;
            if (!files.length)
                return;
            let reader = new FileReader();
            reader.onload = (e) => {
                newFile.image = e.target.result;
            };
            reader.readAsDataURL(files[0]);
            let keyName = event.target.name;
            setTimeout(() => {
                content.image_path =newFile.image;
                tempDocumentList[index] = content;
                this.setState({
                    documentList : tempDocumentList
                },()=>{
                    console.log(this.state.documentList);
                });
            }, 3000);
        }
        else {
            // content[event.target.name][0] = event.target.value;
            this.setState({
                [event.target.name] : event.target.value
            },()=>{
                console.log(this.state.documentList);
            });
        }
    }

    getStatusText = (id) =>{
        if(id==2) {
            return '<span class="badge badge-secondary">Not Approved</span>';
        }
        else if(id==3) {
            return '<span class="badge badge-warning">Correction</span>';
        }
        else if(id==4) {
            return '<span class="badge badge-primary">Review</span>';
        }
        else if(id==5) {
            return '<span class="badge badge-success">Approved</span>';
        }
    }

    checkPermission = (processSettingsId) => {
        let permissionInfo = Auth.getPermissionInfo();
        if(permissionInfo) {
            let processSettingsInfo = permissionInfo.find(x => x.process_archive_settings_id == processSettingsId);
            if(processSettingsInfo) {
                return processSettingsInfo.has_delete == 'yes' ? true: false;
            }
            return false;
        }
        else {
            return false;
        }
    }

    deleteHandler = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to Delete this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
                if (result.value) {
                    axios.post(`${BASEURL}/fileDelete`, { id: id })
                        .then(res => { // then print response status

                            var getRes = res.data;
                            if (getRes.status === 'success') {

                                Swal.fire(
                                    'Deleted!',
                                    'File successfully deleted  from the list.',
                                    'success'
                                )
                                this.getFileList();

                            }
                            else {
                                Swal.fire(
                                    'Warning!',
                                    getRes.message,
                                    'warning'
                                )
                            }


                        })



                }
                else {
                    Swal.fire(
                        'Cancelled!',
                        'Delete operation has canceled',
                        'error'
                    )
                }
            }
        )
    }

    logHistory = (id) => {
        this.setState({
            logHistory :[],
            isHistoryLoaded:false

        });
        axios.get(`${BASEURL}/logHistory?id=`+id)
            .then(res => {
                var fileInfo = res.data;
                this.setState({
                    logHistory : fileInfo.data,
                    isHistoryLoaded:true

                });
            });
    }

    updateHandler = (index) => {
        let fileInfo = this.state.fileList[index];
        // console.log(fileInfo);
        axios.get(`${BASEURL}/getCategories?id=`+fileInfo.category_id)
        .then(res => {
            var category = res.data;
            if(category.data.has_form==1) {
                let properties = JSON.parse(category.data.properties);
                let content = {};
                var form_content = JSON.parse(fileInfo.content);
                console.log(form_content);
                properties.forEach((item,key)=>{
                    content[item.name] = [];
                });



                this.setState({
                    content_code:fileInfo.code,
                    category_id:category.data.id,
                    status: fileInfo.status,
                    input_type:fileInfo.input_type,
                    process_status:fileInfo.process_status==null?1:fileInfo.process_status,
                    forms: properties,
                    form_title:category.data.title,
                    form_content:form_content,
                    file_id:fileInfo.id
                },()=>{
                    console.log(this.state.form_content);
                });
            }
        })
    }

    submitHandler = (event) => {
        event.preventDefault();
        // console.log(this.state.documentList);
        // return false;
        this.setState({
            disabled: true
        });

        axios.post(`${BASEURL}/manualInputProcessFileData`,{
            process_file_settings_id: this.state.process_file_settings_id,
            document_list:this.state.documentList,
            remarks:this.state.remarks
        })
            .then(res => {

                let getRes = res.data;

                if(getRes.status==='success') {
                    this.setState({
                        disabled: false
                    },()=>{
                        this.modalClose();
                        this.getFileList(()=>{
                            Swal.fire(
                                'Saved!',
                                'Process Archive Save Successfully.',
                                'success'
                            )
                        });
                    });

                }
                else {
                    this.setState({
                        disabled: false
                    });
                    Swal.fire(
                        'Error!',
                        'something is wrong please try again',
                        'error'
                    )
                }
            })
    };

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    openModal = (id=0) => {
        this.setState({
            title:'Add New Document',
            input_type:2
        })
    }

    content_code_change = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        });
    }

    submitDeleteStatus = (id) => {
        // event.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be Delete!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {
                axios.post(`${BASEURL}/saveFileDeleteStatus`,{
                    id:id
                })
                    .then(res => {

                        let getRes = res.data;

                        if(getRes.status==='success') {
                            this.setState({
                                disabled: false
                            },()=>{
                                this.modalClose();
                                this.getFileList(()=>{
                                    Swal.fire(
                                        'Saved!',
                                        'File Deleted Successfully.',
                                        'success'
                                    )
                                });
                            });

                        }
                        else {
                            this.setState({
                                disabled: false
                            });
                            Swal.fire(
                                'Error!',
                                'something is wrong please try again',
                                'error'
                            )
                        }
                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }
            else {
                Swal.fire(
                    'Cancelled!',
                    'Status change is cancel',
                    'error'
                )
            }

        });
    }

    render() {
        const ops = this.state.processSettingsList;
        const attachment_path = this.state.attachment_path;
        // permission_info: Auth.getPermissionInfo();
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Files
                        <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-cogs"></i> Archive</li>
                        <li className="active">Process Files</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Process File List</h3>
                                    <button onClick={(e) => {this.openModal(0)}} style={{ float: "right" }} type="button" className="btn btn-primary btn-md" data-toggle="modal" data-target="#modal-default">
                                        <i className="fa fa-plus"></i> New Document
                                    </button>
                                </div>
                                <div className="box-body">
                                    <Loader loaded={this.state.isLoaded}>
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Tracking ID</th>
                                                    <th>File Name</th>
                                                    <th style={{textAlign:"center"}}>Total Document</th>
                                                    <th style={{textAlign:"center"}}>Current Status</th>
                                                    <th>Last Modified</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.fileList.map((item, index) =>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{item.tracking_id}</td>
                                                    <td>{item.process_file_name}</td>
                                                    <td style={{textAlign:"center"}}>{item.total_document}</td>
                                                    <td style={{textAlign:"center"}}>Step- {item.step_status}</td>
                                                    <td>{parse(item.last_modified)}</td>
                                                    <td>
                                                        {/*<button type="button" data-toggle="modal" data-target="#modal-default1" onClick={() => this.displayFile(item.id)} className="btn btn-info btn-md"><i*/}
                                                        {/*    className="fa fa-folder-open"></i> Open File*/}
                                                        {/*</button>*/}
                                                        <NavLink to={"/file_details/"+item.id}>
                                                            <button type="button" className="btn btn-info btn-sm"><i
                                                                className="fa fa-folder-open"></i> Open File</button>
                                                        </NavLink>
                                                        &nbsp;&nbsp;
                                                        {
                                                            (
                                                                Auth.isAdmin() ||  this.checkPermission(item.process_archive_settings_id)!== false ?
                                                                    <button type="button" disabled={this.state.disabled} onClick={()=>{ this.submitDeleteStatus(item.id) }} className="btn btn-sm btn-danger"><i className="fa fa-trash"></i> {this.state.disabled ? 'Saveing': 'Delete'}</button>
                                                            : ''
                                                            )
                                                        }

                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </Loader>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog modal-lg">
                            <form className="form-horizontal"  onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">{this.state.title}</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">
                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-lg-2 col-md-2" htmlFor="email">Select Process File</label>
                                                <div className="col-sm-6 col-md-6 col-lg-6">
                                                    <Select
                                                        onChange={(e)=>{this.contentUpdate(e.value)}}
                                                        options={ops}
                                                        placeholder="Select Process"
                                                        value={this.state.process_file_settings_id} />
                                                </div>
                                            </div>
                                            {
                                                this.state.documentList.length > 0 ? (
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: "40%"}}>Document Name</th>
                                                        <th style={{width: "35%"}}>Upload</th>
                                                        <th style={{width: "25%",textAlign:'center'}}>File</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.documentList.map((item, index) =>
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>
                                                                    <input type="file" onChange={(e) => {this.changeHandler(index, e)}}
                                                                           className="form-control" value={''}
                                                                           name={item.name}
                                                                           placeholder="Enter Full Name"/>
                                                                </td>
                                                                <td>
                                                                    <ReactImageFallback
                                                                        src={item.image_path}
                                                                        fallbackImage={'dist/img/file_default.png'}
                                                                        initialImage={'dist/img/fallback.gif'}
                                                                        alt="cool image should be here"
                                                                        style={{height: "100px", width: "100%"}}
                                                                        className="img-rounded responsive" />
                                                                </td>

                                                            </tr>
                                                        )
                                                    }
                                                    <tr>
                                                        <td color={'3'}><b>Remarks</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="3">
                                                            <textarea name="remarks" onChange={(e) => {this.changeHandler(0, e)}} value={this.state.remarks} className="form-control"
                                                                      placeholder={'Remarks'}></textarea>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                )
                                                    : ''
                                            }



                                            {/*<div className="form-group">*/}
                                            {/*    <label>Process Status:</label>*/}
                                            {/*    <select onChange={this.content_code_change} value={this.state.process_status} name='process_status' className="form-control">*/}
                                            {/*        <option value="1">On Process</option>*/}
                                            {/*        <option value="3">Approved</option>*/}
                                            {/*        <option value="2">Denied</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group" style={{"display":this.state.archive_dropdown ? 'block':'none'}}>*/}
                                            {/*    <label htmlFor="email">Archive Status:</label>*/}
                                            {/*    <select onChange={this.content_code_change} value={this.state.status} name='status' className="form-control">*/}
                                            {/*        <option value="2">Not Archived</option>*/}
                                            {/*        <option value="3">Archived</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.disabled} className="btn btn-primary btn-lg">{this.state.disabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>


                    <div className="modal fade" id="modal-default1">

                        <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">Document Details</h4>
                                    </div>

                                    <div className="modal-body">
                                        <table style={{width:"100%"}}  className="table table-hover table-bordered">
                                            <thead>

                                                <tr>
                                                    <td style={{fontWeight:'bold'}}>Code</td>
                                                    <td>{this.state.file_details.code?this.state.file_details.code:''}</td>
                                                    <td style={{fontWeight:'bold'}}>Category</td>
                                                    <td>{this.state.file_details.categoryName?this.state.file_details.categoryName:''}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'bold'}}>Total Views</td>
                                                    <td>{this.state.file_details.view_count?this.state.file_details.view_count:0}</td>
                                                    <td style={{fontWeight:'bold'}}>Total Download</td>
                                                    <td>{this.state.file_details.download_count?this.state.file_details.download_count:0}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.file_details.properties ?
                                                    this.state.file_details.properties.map((item, index) =>
                                                        <tr key={index}>
                                                            <td style={{fontWeight:'bold'}}>{item.label}</td>
                                                            <td colSpan="3">
                                                                {item.attributes.name !='file' ? item.value:
                                                                    <span>
                                                                        <ReactImageFallback
                                                                            src={item.value}
                                                                            fallbackImage={'dist/img/file_default.png'}
                                                                            initialImage={'dist/img/fallback.gif'}
                                                                            alt="cool image should be here"
                                                                            style={{height: "100px", width: "100%"}}
                                                                            className="img-rounded responsive" />
                                                                    </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                    </div>

                                </div>

                        </div>

                    </div>


                    <div className="modal fade" id="modal-default2">

                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">File Audit History</h4>
                                </div>

                                <div className="modal-body" style={{"height":"300px","overflow-y":"scroll"}}>
                                    <Loader loaded={this.state.isHistoryLoaded}>
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Log Status</th>
                                                    {/*<th>User level</th>*/}
                                                    <th>Log User</th>
                                                    <th>Log Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.logHistory.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{parse(item.archive_status_name)}</td>
                                                    {/*<td>{item.user_level_name}</td>*/}
                                                    <td>{parse(item.modified_info)}</td>
                                                    <td>{item.updated_time}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </Loader>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                </div>

                            </div>

                        </div>

                    </div>


                </section>
            </div>






        );
    }
}

export default ProcessFileList;
