import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from '../../service/Server'
import { NavLink , withRouter  } from 'react-router-dom'
import ReactImageFallback from "react-image-fallback";

document.title = "Input File Content";
const BASEURL = Server.baseUrl;
const FileDir = BASEURL + "/getFile/";
class InputContent extends Component {
    constructor(props) {
        super(props);
        this.myForm = React.createRef();
        this.state = {
            isLoaded: false,
            disabled:false,
            search_code: this.props.match.params.id,
            categories: [],
            fileList: [],
            forms:[],
            form_title:"",
            form_content:{},
            file_id:"",
        };

    }

    getFileList = (callBack= () => {}) => {
        axios.get(`${BASEURL}/getFileList`, {
            params: {
                code: this.state.search_code
            }
        })
            .then(res => {
                var getRes = res.data;
                this.setState({
                    isLoaded: true,
                    fileList: getRes.data
                },()=>{
                    callBack();
                });
            })
    }

    componentDidMount() {
        axios.get(`${BASEURL}/getCategories`)
            .then(res => {
                var category = res.data;
                this.setState({
                    categories: category.data
                }, () => {
                    this.getFileList();
                });
            })
    }

    componentWillReceiveProps(newProps) {
        
        this.setState({
            search_code: newProps.match.params.id,
        },()=>{
            this.getFileList();
        });
    }

    callNext = () => {
        this.setState({
            disabled: true
        });
        axios.get(`${BASEURL}/getScancompleteCode`,{
            params:{
                code:this.state.search_code
            }
        })
        .then(response => {
            this.setState({
                disabled: false
            });
            let scanRes = response.data;
            if(scanRes.status==='success') {
                this.props.history.push('/input_content/'+scanRes.data[0].code);
            }
        })
    }

    

    contentUpdate = (index) => {

        let fileInfo = this.state.fileList[index];
        // console.log(fileInfo.category_id);

        axios.get(`${BASEURL}/getCategories?id=`+fileInfo.category_id)
            .then(res => {
                var category = res.data.data;
                console.log(category);
                // let category = this.state.categories.filter((item)=>item.id===fileInfo.category_id);
                // let category = category.data.properties;
                // console.log(this.state.categories);
                // return false;
                let properties = JSON.parse(category.properties);
                let content = {};

                if(fileInfo.status > 1) {
                    let file_content = JSON.parse(fileInfo.content);
                    properties.forEach((item)=>{
                        content[item.name] = file_content.hasOwnProperty(item.name) ? file_content[item.name] : [];
                    });
                }
                else {
                    properties.forEach((item)=>{
                        content[item.name] = [];
                    });
                }

                this.setState({
                    forms: properties,
                    form_title:category.title,
                    form_content:content,
                    file_id:fileInfo.id
                },()=>{
                    // console.log(this.state);
                });
            })


    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true
        });
        
        axios.post(`${BASEURL}/inputFileData`,{
            content: this.state.form_content,
            id:this.state.file_id
        })
        .then(res => {

            let getRes = res.data;
            
            if(getRes.status==='success') {
                this.setState({
                    disabled: false
                },()=>{
                    this.modalClose();
                    this.getFileList(()=>{
                        Swal.fire(
                            'Saved!',
                            'File Content Save Successfully.',
                            'success'
                        )
                    });
                });
                
            }
            else {
                this.setState({
                    disabled: false
                });
                Swal.fire(
                    'Error!',
                    'something is wrong please try again',
                    'error'
                )
            }
        }) 
    }

    changeHandler = (event) => {
        let content = this.state.form_content;
        // console.log(content);
        if(event.target.type === "checkbox") {
            if(event.target.checked){
                content[event.target.name].push(event.target.value);
                this.setState({
                    form_content : content
                },() => {
                    console.log(this.state.form_content);
                })
            }
            else {
                let items = content[event.target.name].filter(item => item !== event.target.value);
                content[event.target.name] = items;
                this.setState({
                    form_content : content
                },()=>{
                    console.log(this.state.form_content);
                })
            }
        }
        else if(event.target.type === "file") {
            var newFile = {
                file: event.target.files[0].name,
                image: '',
                src: URL.createObjectURL(event.target.files[0])
            };

            let files = event.target.files;
            if (!files.length)
                return;
            let reader = new FileReader();
            reader.onload = (e) => {
                newFile.image = e.target.result;
            };
            reader.readAsDataURL(files[0]);
            let keyName = event.target.name;
            setTimeout(() => {
                content[keyName][0]=newFile.image;
                this.setState({
                    form_content : content
                },()=>{
                    console.log(this.state.form_content);
                });
            }, 3000);
        }
        else {
            content[event.target.name][0] = event.target.value; 
            this.setState({
                form_content : content
            },()=>{
                console.log(this.state.form_content);
            });
        }
    }


    getCategoryName = (category_id) => {
        let category = this.state.categories.filter(function (item) {
            return item.category_id === category_id
        });

        if (category.length > 0) {
            return "category[0].title";
        }
        else {
            return "Unknown Category";
        }
    }


    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }



    render() {
        return (
            <div className="content-wrapper">
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                            <div className="row">
                                <div className="col-lg-12 col-xs-12 col-md-12">

                                    <div className="box box-default">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">File Scan Code-<b>{this.state.search_code}</b></h3>
                                        </div>

                                        <div className="box-body" style={{ minHeight: "300px" }}>

                                            <div className="row">
                                                {
                                                    this.state.fileList.map((item, index) =>

                                                        <div key={index} className="col-md-3 col-lg-3 col-xs-3">
                                                            <div className={item.status > 1 ? "panel panel-success":"panel panel-default"}>
                                                                <div className="panel-heading">
                                                                    <b>{ item.category_id > 0 ? item.categoryName : "Unknown Category" }</b>
                                                                </div>
                                                                <div className="panel-body">
                                                                    <img src={item.file_path} style={{ width: "100%", height: "200px" }} className="img-thumnail" alt="logo" />
                                                                </div>
                                                                <div className="panel-footer">
                                                                    <div>
                                                                        {
                                                                            item.category_id > 0 ?
                                                                            <button type="button"  data-toggle="modal" data-target="#modal-default" onClick={() => { this.contentUpdate(index) }} className={item.status > 1 ? "btn btn-success btn-sm" : "btn btn-info btn-sm"}><i className="fa fa-edit"></i> {item.status > 1 ?"Update Data":"Input Data"}</button>
                                                                            :
                                                                            "Unknown Category"
                                                                        }

                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </div>

                                        <div className="box-footer">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-4">
                                                
                                                </div>
                                                <div className="col-md-5 col-sm-5">
                                                    <button type="button" disabled={this.state.disabled} onClick={()=>{ this.callNext() }} className="btn btn-md btn-primary">{this.state.disabled ? 'Requesting': 'Next'}</button>
                                                    &nbsp;&nbsp;
                                                    <NavLink to="/file_content">
                                                        <button type="button" className="btn btn-md btn-danger">Back</button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                    </Loader>
                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">

                            <form  onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">{this.state.form_title}</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            {
                                                this.state.forms.map((item, index) =>
                                                    <div key={index} className="form-group">
                                                        <label htmlFor="name">{item.label}</label>
                                                        {
                                                            item.attributes.name === "select" ? 
                                                            <select onChange={this.changeHandler} value={this.state.form_content[item.name][0]} name={item.name} className="form-control">
                                                                <option value="">Select Gender</option>
                                                                {
                                                                    item.attributes.hasoption===true && item.attributes.options.length > 0 ?
                                                                    item.attributes.options.map((option,optionKey) => 
                                                                        <option key={optionKey} value={option.value}>{option.name}</option>
                                                                    )
                                                                    :
                                                                    ""
                                                                }
                                                            </select>
                                                            :
                                                            (
                                                            item.attributes.name === "textarea" ? 
                                                            <textarea onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name}></textarea>
                                                            :
                                                            (
                                                            item.attributes.name === "checkbox" ? 
                                                            <div>
                                                                {
                                                                    item.attributes.options.map((option,optionKey)=>
                                                                        <div key={optionKey}>
                                                                            <span>{option.name}</span> <input type={item.attributes.name} name={item.name} checked={this.state.form_content[item.name].indexOf(option.value) >= 0  ? true: false } value={option.value} onChange={this.changeHandler}/>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            :
                                                                (
                                                                        item.attributes.name=='file' ?
                                                                            (
                                                                                <span>
                                                                                    <ReactImageFallback
                                                                                        src={this.state.form_content[item.name][0]}
                                                                                        fallbackImage={'dist/img/file_default.png'}
                                                                                        initialImage={'dist/img/fallback.gif'}
                                                                                        alt="cool image should be here"
                                                                                        style={{height: "100px", width: "100%"}}
                                                                                        className="img-rounded responsive" />
                                                                                    <input type={item.attributes.name} onChange={this.changeHandler} className="form-control" value={''} name={item.name} placeholder="Enter Full Name" />
                                                                                </span>

                                                                            )

                                                                    :
                                                                    <input type={item.attributes.name} onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name} placeholder="Enter Full Name" />
                                                                )


                                                            )
                                                            )
                                                        }
                                                        
                                                    </div>
                                                )
                                            }

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.disabled} className="btn btn-primary">{this.state.disabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(InputContent);
