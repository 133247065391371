
import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from './../../service/Server'

const BASEURL = Server.baseUrl;

const intitialState = {
    name: '',
    sorting: '',
    id: '',
    isLoaded: false,
    error: false,
    errorMessage: {},
    designations: [],
    isBtnDisabled: false,
    barcode_sufix: '',
    barcode_prefix: '',
    max_user_level: 1,
    barcode_length: 5,
};

class GeneralSetting extends Component {

    constructor(props) {
        super(props)
        this.myForm = React.createRef()
        this.state = intitialState;
        document.title = "Designation";
    }

    getGenealSetting = (callBack = () => {}) => {
        axios.get(`${BASEURL}/getGeneralSetting`)
            .then(response => {
                var getRes = response.data;
                if(getRes.status=='success') {
                    this.setState({
                        barcode_length: getRes.data.barcode_length,
                        max_user_level: getRes.data.max_user_level,
                        barcode_prefix: getRes.data.barcode_prefix,
                        barcode_sufix: getRes.data.barcode_sufix,
                        isLoaded:true
                    });
                }
                else {
                    this.setState({
                        isLoaded:true
                    });
                }

            })
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getGenealSetting();
    }

    submitHandler = (event) => {

        event.preventDefault();

        var designationData = {
            "barcode_length": this.state.barcode_length,
            "max_user_level": this.state.max_user_level,
            "barcode_prefix": this.state.barcode_prefix,
            "barcode_sufix": this.state.barcode_sufix
        };

        this.setState({
            isBtnDisabled: true
        });
        axios.post(`${BASEURL}/saveGeneralSetting`, designationData)
            .then(res => {
                var getRes = res.data;
                this.setState({
                    isBtnDisabled: false
                });
                if (getRes.status === 'success') {

                    Swal.fire(
                        'Saved!',
                        'General Setting Saved Successfully',
                        'success'
                    );

                    // this.modalClose();

                }
                else {
                    // var errors = JSON.parse(getRes.message);
                    // var list = Object.keys(errors).map(function (key) {
                    //     return <li key={key}>{errors[key][0]}</li>
                    // });
                    // this.setState({
                    //     errorMessage: list,
                    //     error: true,
                    //     isBtnDisabled: false
                    // });
                }


            })
            .catch(error => {
                this.setState({
                    error: true,
                    isBtnDisabled: false
                })
            })

    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        General Setting
                        <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-cogs"></i> Configuration</li>
                        <li className="active">General Setting</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">General Setting</h3>
                                    </div>
                                    <div className="box-body">

                                        <form className="form-horizontal" onSubmit={this.submitHandler}>
                                            <div className="form-group">
                                                <label className="control-label col-sm-2" htmlFor="email">Barcode Length:</label>
                                                <div className="col-sm-6">
                                                    <input type="text" name="barcode_length" value={this.state.barcode_length} onChange={this.changeHandler} className="form-control" id="barcode_length"
                                                           placeholder="Barcode Length"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-sm-2"
                                                       htmlFor="pwd">Barcode Prefix:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" name="barcode_prefix" value={this.state.barcode_prefix} onChange={this.changeHandler} className="form-control" id="pwd"
                                                           placeholder="Barcode Prefix"/>
                                                </div>
                                                <label className="control-label col-sm-2"
                                                       htmlFor="pwd">Barcode Sufix:</label>
                                                <div className="col-sm-2">
                                                    <input type="text" name="barcode_sufix" value={this.state.barcode_sufix} onChange={this.changeHandler} className="form-control" id="pwd"
                                                           placeholder="Barcode Suffix"/>
                                                </div>
                                            </div>
                                            {/*<div className="form-group">*/}
                                            {/*    <label className="control-label col-sm-2" htmlFor="email">Max User Level:</label>*/}
                                            {/*    <div className="col-sm-6">*/}
                                            {/*        <input type="text" name="max_user_level" value={this.state.max_user_level} onChange={this.changeHandler} className="form-control" id="max_user_level"*/}
                                            {/*               placeholder="Max User Level"/>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="form-group">
                                                <div className="col-sm-offset-2 col-sm-10">
                                                    <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">{this.state.isBtnDisabled?'Saveing' : 'Save'}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Loader>
                </section>
            </div>
        );

    }
}

export default GeneralSetting;
