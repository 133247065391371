
import React from 'react'
import { Redirect } from 'react-router-dom'
import Header from './template/Header';
import SideBar from './template/SideBar';
import Dashboard from './template/Dashboard';
import Footer from './template/Footer';
import Archive from './../routes/Archive';
import Configuration from './../routes/Configuration';
import PrivateRoute from './auth/PrivateRoute';
import Logout from './auth/Logout';
import Auth from './../service/Auth';

class PrivateComponnent  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
        if(Auth.isLogin) {
            Auth.setToken();
        }
    }


    render() {
        return (
            <div className="wrapper">
                <PrivateRoute exact path="/" component={() =>
                    <Redirect to="/dashboard" />
                } />
                <PrivateRoute path="/" component={() =>
                    <Header />
                } />
                <PrivateRoute path="/" component={() =>
                    <SideBar />
                } />
                <PrivateRoute exact path="/dashboard" component={() =>
                    <Dashboard />
                } />

                <Archive />

                <Configuration />

                <PrivateRoute exact path="/logout" component={() =>
                    <Logout />
                } />
                <PrivateRoute path="/" component={() =>
                    <Footer />
                } />
            </div>
        );
    }
}

export default PrivateComponnent;
