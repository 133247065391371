// Header.js
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class Header extends Component {
    render() {
        return (
            <header className="main-header">
                <NavLink to="/" className="logo">
                    <span className="logo-mini"><b>A</b>LT</span>
                    <span className="logo-lg"><b>Admin</b></span>
                </NavLink>
                <nav className="navbar navbar-static-top">
                    <NavLink to="/" className="sidebar-toggle" data-toggle="push-menu" role="button">
                        <span className="sr-only">Toggle navigation</span>
                    </NavLink>
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            <li className="dropdown messages-menu">
                                <NavLink to="/" className="dropdown-toggle" data-toggle="dropdown">
                                    <i className="fa fa-envelope-o"></i>
                                    <span className="label label-success">4</span>
                                </NavLink>
                                <ul className="dropdown-menu">
                                    <li className="header">You have 4 messages</li>
                                    <li>
                                        <ul className="menu">
                                            <li>
                                                <NavLink to="/">
                                                    <div className="pull-left">
                                                        <img src="dist/img/user2-160x160.jpg" className="img-circle" alt="User" />
                                                    </div>
                                                    <h4>
                                                        Support Team
                                                        <small><i className="fa fa-clock-o"></i> 5 mins</small>
                                                    </h4>
                                                    <p>Why not buy a new awesome theme?</p>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li className="dropdown notifications-menu">
                                <NavLink to="/" className="dropdown-toggle" data-toggle="dropdown">
                                    <i className="fa fa-bell-o"></i>
                                    <span className="label label-warning">10</span>
                                </NavLink>

                                <ul className="dropdown-menu">
                                    <li className="header">You have 10 notifications</li>
                                    <li>
                                        <ul className="menu">
                                            <li>
                                                <NavLink to="/">
                                                    <i className="fa fa-users text-aqua"></i> 5 new members joined today
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="footer"><NavLink to="/">View all</NavLink></li>
                                </ul>

                            </li>

                            <li className="dropdown user user-menu">
                                <NavLink to="/logout">
                                    <i className="fa fa-sign-out"></i>
                                    <span className="hidden-xs">Logout</span>
                                </NavLink>
                            </li>

                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Header;