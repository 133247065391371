
import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from './../../service/Server'
import Select from "../../Select";
import parse from 'html-react-parser';

const BASEURL = Server.baseUrl;

const intitialState = {
    archive_name: '',
    total_step: '',
    user_id: '',
    files_name: [],
    permission: [],
    step_array: [],
    temp_file_name: '',
    designation_id: '',
    level_id: '',
    user_type: '',
    username: '',
    id: '',
    isLoaded: false,
    error: false,
    errorMessage: {},
    archiveConfigurationList: [],
    users: [],
    isBtnDisabled: false,
    departments: [],
    designations: [],
    levelData: [],

};

class ProcessArchive extends Component {

    constructor(props) {
        super(props)
        this.myForm = React.createRef()
        this.state = intitialState;
        document.title = "User";

    }

    openModal = () => {
        this.setState({
            archive_name: '',
            total_step: '',
            files_name: [],
            permission: [],
            step_array: [],
            temp_file_name: '',
            designation_id: '',
            user_type: '',
            username: '',
            level_id: '',
            id: '',
            error: false,
        });
    }

    getArchiveSettingsList = (callBack = () => { }) => {
        axios.get(`${BASEURL}/getArchiveSettingsList`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    archiveConfigurationList: getRes.data,
                    isLoaded: true,
                }, () => {
                    callBack();
                    this.getUsers();
                });

                // this.setState({
                //     name:'value'
                // },() => {
                //     console.log(this.state.name);
                // });

            })
            .catch(error => console.log(error));
    }



    componentDidMount() {
        this.getArchiveSettingsList();
        this.getUsers();

    }


    removeFile(index) {
        let currenttemp_properties = this.state.files_name;
        currenttemp_properties.splice(index, 1);
        this.setState({
            files_name: currenttemp_properties
        });
    }

    addFiles = (event) => {
        let currenttemp_properties = this.state.files_name;
        currenttemp_properties.push(
            {
                name: this.state.temp_file_name,
            }
        );

        this.setState(
        {
                files_name: currenttemp_properties
            },
            () => {
                this.setState({
                    temp_file_name: ''
                });
            });
    }


    update = (index) => {
        var user = this.state.archiveConfigurationList[index];
        this.setState({
            archive_name: user.name,
            total_step: user.total_step,
            files_name: user.files,
            temp_file_name: '',
            id: user.id,
            error: false,
        });
    }



    updatePermission = (index) => {
        var user = this.state.archiveConfigurationList[index];
        let permissionInfo = user.permission;
        let totalStep = user.total_step;
        let step_array = [];
        for(let i=1; i <= totalStep;i++) {
            let stepInfo = permissionInfo.find(x => x.step_id == i);
            if(typeof stepInfo === 'object') {
                step_array.push({
                    step_id: i,
                    name: 'Step-' + i,
                    has_edit: stepInfo.has_edit && stepInfo.has_edit == 'yes' ? 'yes' : 'no',
                    has_delete: stepInfo.has_delete && stepInfo.has_delete == 'yes' ? 'yes' : 'no',
                    has_view: stepInfo.has_view && stepInfo.has_view == 'yes' ? 'yes' : 'no',
                    has_comment: stepInfo.has_comment && stepInfo.has_comment == 'yes' ? 'yes' : 'no',
                    user_id: stepInfo.user_id  ? stepInfo.user_id : 0,
                });
            }
            else {
                step_array.push({
                    step_id: i,
                    name: 'Step-' + i,
                    has_edit: 'no',
                    has_delete: 'no',
                    has_view: 'yes',
                    has_comment: 'yes',
                    user_id:  0
                });
            }
        }
        this.state.step_array = step_array;
        this.setState({
            archive_name: user.name,
            total_step: user.total_step,
            files_name: user.files,
            permission: user.permission,
            step_array: this.state.step_array,
            temp_file_name: '',
            id: user.id,
            error: false,
        });
    }

    handleCheckBoxClick = (index,e) => {
        let stepArray = this.state.step_array;
        let checked = e.target.checked;
        let name = e.target.name;
        if(name=='has_edit') {
            stepArray[index].has_edit = checked ? 'yes' : 'no';
        }
        else if(name=='has_view') {
            stepArray[index].has_view = checked ? 'yes' : 'no';
        }
        else if(name=='has_delete') {
            stepArray[index].has_delete = checked ? 'yes' : 'no';
        }
        else if(name=='has_comment') {
            stepArray[index].has_comment = checked ? 'yes' : 'no';
        }
        this.setState({
            step_array:stepArray
        })
    }

    dropDownUpdate = (id,index) => {
        let step_array = this.state.step_array;
        step_array[index].user_id = id;
        this.setState({
            step_array: step_array
        })
    }

    delete = (id) => {
        var userid = id;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {

                axios.post(`${BASEURL}/deleteProcessSettings`, { id: userid })
                    .then(res => {
                        var getRes = res.data;
                        if (getRes.status === 'success') {
                            this.setState({
                                isLoaded: false
                            });
                            this.getArchiveSettingsList(() => {Swal.fire(
                                'Deleted!',
                                'Process Archive Deleted Successfully',
                                'success'
                            )});
                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                'User delete is fail',
                                'warning'
                            )
                        }


                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }

            else {
                Swal.fire(
                    'Cancelled!',
                    'User delete is cancel',
                    'error'
                )
            }
        })

    }

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    permissionModalClose = () => {
        var el = document.getElementById('permissionModalCloseid');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    getUsers = (callBack = () => { }) => {
        axios.get(`${BASEURL}/getAllUser`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    users: getRes.data,
                }, () => {
                    callBack();
                });

            })
            .catch(error => console.log(error));
    }

    submitHandler = (event) => {

        event.preventDefault();

        var userdata = {
            "archive_name": this.state.archive_name,
            "total_step": this.state.total_step,
            "files_name": this.state.files_name,
            "id": this.state.id
        };

        // console.log(userdata);
        // return false;

        this.setState({
            isBtnDisabled: true
        });

        if (this.state.id === '') {
            axios.post(`${BASEURL}/saveProcessSettings`, userdata)
                .then(res => {
                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            archive_name: '',
                            total_step: '',
                            files_name: [],
                            id: '',
                            isLoaded: false,
                            error: false,
                            errorMessage: {},
                            users: [],
                            isBtnDisabled: false,
                        });

                        this.getArchiveSettingsList(() => {
                            Swal.fire(
                                'Added!',
                                'New Archive Process Save Successfully',
                                'success'
                            )
                        });

                        this.modalClose();



                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }

        else {
            axios.post(`${BASEURL}/saveProcessSettings`, userdata)
                .then(res => {

                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            archive_name: '',
                            total_step: '',
                            files_name: [],
                            id: '',
                            isLoaded: false,
                            error: false,
                            errorMessage: {},
                            users: [],
                            isBtnDisabled: false,
                        });

                        this.getArchiveSettingsList(() => {
                            Swal.fire(
                                'Added!',
                                'Archive Process Save Successfully',
                                'success'
                            )
                        });

                        this.modalClose();

                    }
                    else {
                        var errors = getRes.message;
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false,
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }
    }


    permissionSubmitHandler = (event) => {

        event.preventDefault();

        var userdata = {
            "id": this.state.id,
            "permission_info": this.state.step_array
        };

        // console.log(userdata);
        // return false;

        this.setState({
            isBtnDisabled: true
        });

        axios.post(`${BASEURL}/saveProcessPermission`, userdata)
            .then(res => {
                var getRes = res.data;
                if (getRes.status === 'success') {
                    this.myForm.current.reset();
                    this.setState({
                        archive_name: '',
                        total_step: '',
                        files_name: [],
                        step_array: [],
                        id: '',
                        isLoaded: false,
                        error: false,
                        errorMessage: {},
                        users: [],
                        isBtnDisabled: false,
                    });

                    this.getArchiveSettingsList(() => {
                        Swal.fire(
                            'Added!',
                            'Process Permission Save Successfully',
                            'success'
                        )
                    });

                    this.permissionModalClose();
                }
                else {
                    var errors = JSON.parse(getRes.message);
                    var list = Object.keys(errors).map(function (key) {
                        return <li key={key}>{errors[key][0]}</li>
                    });
                    this.setState({
                        errorMessage: list,
                        error: true,
                        isBtnDisabled: false
                    });
                }


            })
            .catch(error => {
                this.setState({
                    error: true,
                    isBtnDisabled: false
                })
            })
    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

    }

    render() {
        const ops = this.state.users;
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Process Archive
                        <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-cogs"></i> Configuration</li>
                        <li className="active">Process Archive</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Process Configuration List</h3>
                                        <button onClick={this.openModal} style={{ float: "right" }} type="button" className="btn btn-primary btn-md" data-toggle="modal" data-target="#modal-default">
                                            <i className="fa fa-plus"></i> New Process
                                        </button>
                                    </div>
                                    <div className="box-body">

                                        <table style={{ width: "100%" }} className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{width:"5%"}}>SL</th>
                                                    <th style={{width:"15%"}}>Name</th>
                                                    <th className={'text-center'} style={{width:"10%"}}>Total Files</th>
                                                    <th className={'text-center'} style={{width:"10%"}}>Total Step</th>
                                                    <th style={{width:"15%"}}>Last Update</th>
                                                    <th style={{width:"15%"}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.archiveConfigurationList.map((user, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {user.name}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        <span className="badge badge-pill badge-primary" style={{backgroundColor: "green",width:"50px;"}}>{user.total_files}</span>
                                                    </td>
                                                    <td className={'text-center'}>
                                                        <span className="badge badge-pill badge-primary" style={{backgroundColor: "green",width:"50px;"}}>{user.total_step}</span>
                                                    </td>
                                                    <td>{ parse(user.last_modified) }</td>
                                                    <td>
                                                        <button data-toggle="modal" data-target="#modal-default" onClick={() => { this.update(index) }} className="btn btn-info btn-sm"><i className="fa fa-pencil"></i> Update</button>
                                                        &nbsp;
                                                        <button data-toggle="modal" data-target="#modal-permission" onClick={() => { this.updatePermission(index) }} className="btn btn-primary btn-sm"><i className="fa fa-pencil-square"></i> Permission</button>
                                                        &nbsp;
                                                        <button onClick={() => { this.delete(user.id) }} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i> Delete</button>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Loader>


                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">

                            <form ref={this.myForm} role="form" onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">Process Configuration</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            <div className="form-group">
                                                <label htmlFor="name">Process Name</label>
                                                <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.archive_name} name="archive_name" placeholder="Process Name" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="email">Archiving Steps</label>
                                                <input type="number" onChange={this.changeHandler} className="form-control" value={this.state.total_step} name="total_step" placeholder="Archiving Steps" />
                                            </div>

                                            {/*<div className="form-group">*/}
                                            {/*    <label htmlFor="mobile">Number Of Files</label>*/}
                                            {/*    <input type="text" onChange={this.fileChangeHandler} className="form-control" value={this.state.mobile} name="total_files" placeholder="Number of files with this process" />*/}
                                            {/*</div>*/}

                                            <table  className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "100%" }} colspan="2">Configuration file name with this process</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td style={{ width: "100%" }}>
                                                        <input type="text"  onChange={this.changeHandler} className="form-control" value={this.state.temp_file_name}   name="temp_file_name" placeholder="File Name" />
                                                    </td>
                                                    <td>
                                                        <button type="button" onClick={() => this.addFiles()} className="btn btn-info"> <i className="fa fa-plus"></i> Add</button>
                                                    </td>
                                                </tr>
                                                {

                                                    this.state.files_name.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>

                                                                <input type="text" readOnly className="form-control" value={item.name} name="" placeholder="File Name" />
                                                            </td>
                                                            <td>
                                                                <button type="button" onClick={() => this.removeFile(index)} className="btn btn-danger"> <i className="fa fa-trash"></i> Remove</button>
                                                            </td>
                                                        </tr>
                                                    )

                                                }
                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left closeBtn" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">{this.state.isBtnDisabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>


                    <div className="modal fade" id="modal-permission">

                        <div className="modal-dialog">

                            <form ref={this.myForm} role="form" onSubmit={this.permissionSubmitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">Process Permission</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            <div className="form-group">
                                                <label htmlFor="name">Process Name</label>
                                                <input type="text" readOnly className="form-control" value={this.state.archive_name} name="archive_name" placeholder="Process Name" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="email">Archiving Steps</label>
                                                <input type="number" readOnly className="form-control" value={this.state.total_step} name="total_step" placeholder="Archiving Steps" />
                                            </div>

                                            <table  className="table">
                                                <thead>
                                                    <tr>
                                                        <th colspan="6">Add Archive Permission with this process</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <th style={{width:"15%"}}>Step</th>
                                                    <th style={{width:"20%"}}>User</th>
                                                    <th style={{width:"5%","text-align":"left"}}>Edit</th>
                                                    <th style={{width:"5%","text-align":"left"}}>Delete</th>
                                                    <th style={{width:"5%","text-align":"left"}}>View</th>
                                                    <th style={{width:"5%","text-align":"left"}}>Comment</th>
                                                </tr>
                                                {

                                                    this.state.step_array.length > 0 ? (
                                                            this.state.step_array.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input type="text" readOnly className="form-control" value={item.name} name="" placeholder="File Name" />
                                                                        <input type="hidden" readOnly className="form-control" value={item.step_id} name="" placeholder="File Name" />
                                                                    </td>
                                                                    <td>
                                                                        <Select
                                                                            onChange={(e)=>{this.dropDownUpdate(e.value,index)}}
                                                                            options={ops}
                                                                            placeholder="Select User"
                                                                            value={item.user_id} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" checked={item.has_edit=='yes' ? 'checked':false}  onChange={(e) => {this.handleCheckBoxClick(index, e)}} name="has_edit" value="yes"/>
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" checked={item.has_delete=='yes' ? 'checked':false}  onChange={(e) => {this.handleCheckBoxClick(index, e)}} name="has_delete" value="yes"/>
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" checked={item.has_view=='yes' ? 'checked':false}  onChange={(e) => {this.handleCheckBoxClick(index, e)}} name="has_view" value="yes"/>
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" checked={item.has_comment=='yes' ? 'checked':false}  onChange={(e) => {this.handleCheckBoxClick(index, e)}} name="has_comment" value="yes"/>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        )
                                                        :
                                                        (
                                                            ''
                                                        )
                                                }
                                                </tbody>
                                            </table>

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="permissionModalCloseid" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">{this.state.isBtnDisabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>


                </section>
            </div>
        );

    }
}

export default ProcessArchive;
