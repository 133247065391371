import axios from 'axios'

const Auth = {
    isLogin: localStorage.getItem("user")? true : false,
    login(callBack){
        this.isLogin=  true ;
        callBack();
    },
    logout(callBack) {
        this.isLogin=false
        localStorage.removeItem("user");
        axios.defaults.headers.common['Authorization'] = '';
        callBack();
    },
    getToken(){
        let user = JSON.parse(localStorage.getItem("user"))
        return user.token;
    },
    isAdmin(){
        let user = JSON.parse(localStorage.getItem("user"))
        return user.is_admin;
    },
    getUserId(){
        let user = JSON.parse(localStorage.getItem("user"))
        return user.user_id;
    },
    getPermissionInfo(){
        let user = JSON.parse(localStorage.getItem("user"))
        return user.permission_info;
    },
    setToken() {
        let user = JSON.parse(localStorage.getItem("user"));
        axios.defaults.headers.common = {'Authorization': `Bearer ${user.token}`}
    }
}

export default Auth