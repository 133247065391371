import React, {Component} from 'react';
import axios from 'axios';
import Server from './../../service/Server';
import Loader from 'react-loader';
import InfiniteScroll from 'react-infinite-scroller';
import ReactImageFallback from "react-image-fallback";

import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import Select from '../../Select';
import 'react-select-plus/dist/react-select-plus.css';
import parse from "html-react-parser";

document.title = 'Barcode Generatator';
const BASEURL = Server.baseUrl;
const FileDir = BASEURL+"/getFile/";

const intitialState = {
    fileList: [],
    categories: [],
    id: '',
    total: 0,
    isLoaded: false,
    error: false,
    errorMessage: {},
    isBtnDisabled: false,
    isUpdate: false,
    search_string:'',
    item:'',
    hasMoreItems:true,
    start:0,
    limit:10,
    displayUrl:'',
    file_details:{},
    isHistoryLoaded:false,
    logHistory:[]
}

class Report extends Component {

    constructor(props) {
        super(props)
        this.state = intitialState;
    }

    getFileList = () => {
        this.setState({isLoaded: false, fileList: [],start:0,limit:10,hasMoreItems:true},() => {
            axios.get(`${BASEURL}/getFileReport?search_string=${this.state.search_string} &item=${this.state.item}
            &start=${this.state.start}&limit=${this.state.limit}`)
                .then(response => {
                    var getRes = response.data;
                    if(getRes.status='success') {
                        this.setState({
                            fileList: getRes.data,
                            total: getRes.total,
                            isLoaded: true,
                            start: getRes.start,
                            limit: 10,
                        });
                    }
                    else {
                        this.setState({
                            fileList: [],
                            total: 0,
                            isLoaded: true,
                            start: 0,
                            limit: 10,
                        });
                    }
                })
                .catch(error => console.log(error));
        });

    }

    resetSearch = () => {
        this.setState({
            search_string:'',
            item:''
        },() => {
            this.getFileList()
        })
    }

    displayFile = (id) => {
        axios.get(`${BASEURL}/displayFile?id=`+id)
            .then(res => {
                var fileInfo = res.data;
                this.setState({
                    attachment_path:fileInfo.attachment_path,
                    file_details:fileInfo.data
                });
                console.log(fileInfo);
            });
    };

    loadMoreData = (callBack = () => {
    }) => {
        axios.get(`
        ${BASEURL}/getFileReport?search_string=${this.state.search_string}&item=${this.state.item}
        &start=${this.state.start} &limit=${this.state.limit}
        `)
            .then(response => {
                let getRes = response.data;
                if(getRes.status=='success') {
                    let fileList = this.state.fileList;
                    getRes.data.map((track) => {
                        fileList.push(track);
                    });

                    if (this.state.fileList.length == getRes.total) {
                        this.setState({
                            isLoaded: true,
                            hasMoreItems: false,
                            start: 0,
                        })
                    } else {
                        this.setState({
                            fileList: fileList,
                            total: getRes.total,
                            start: getRes.start,
                            isLoaded: true,
                            hasMoreItems: true
                        })
                    }
                }
                else {
                    this.setState({
                        isLoaded: true,
                        hasMoreItems: false,
                        start: 0,
                    })
                }


            })
            .catch(error => console.log(error));
    }

    changeHandler = (id,name) => {
        console.log(id);
        this.setState({
            [name]: id
        }, () => {
            // console.log(this.state);
        })

    }

    getCategory = () => {
        axios.get(`${BASEURL}/getCategoriesDropdown`)
            .then(res => {
                var category = res.data;
                this.setState({
                    categories: category.data
                }, () => {
                    this.getFileList();
                });
            })
    }

    logHistory = (id) => {
        this.setState({
            logHistory :[],
            isHistoryLoaded:false

        });
        axios.get(`${BASEURL}/logHistory?id=`+id)
            .then(res => {
                var fileInfo = res.data;
                this.setState({
                    logHistory : fileInfo.data,
                    isHistoryLoaded:true

                });
            });
    }

    componentDidMount() {
        this.getCategory();
    }

    render() {

        const ops = this.state.categories;
        const loader = <div className="loader" style={{textAlign:'center'}}><img src={'dist/img/dataloader.gif'}/></div>;
        var items = [];
        this.state.fileList.map((item, index) => {
            items.push(
                <div className="col-md-3 col-lg-3" key={index}>
                    <div className="panel panel-info">
                        <div className="panel-heading"
                             style={{color: "black", padding: "5px !important"}}>
                            <b>{item.categoryName}</b> / <b>Code No:</b> {item.code}
                            <div className="clearfix"></div>
                            Archived: {item.archive_time} ({item.archived_by_name})
                        </div>
                        <div className="panel-body">
                            <ReactImageFallback
                                src={item.file_path}
                                fallbackImage={'dist/img/file_default.png'}
                                initialImage={'dist/img/fallback.gif'}
                                alt="cool image should be here"
                                style={{height: "100px", width: "100%"}}
                                className="img-rounded responsive" />
                        </div>
                        <div className="panel-footer">
                            <button type="button" data-toggle="modal" data-target="#modal-default1" onClick={() => this.displayFile(item.id)} className="btn btn-info btn-xs"><i
                                className="fa fa-eye"></i> Details
                            </button>
                            &nbsp;&nbsp;
                            <button type="button" data-toggle="modal" data-target="#modal-default2" onClick={() => this.logHistory(item.id)} className="btn btn-success btn-xs"><i
                                className="fa fa-list-alt"></i> File Log
                            </button>
                        </div>
                    </div>

                </div>
            );
        });
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Archive Search
                        <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-archive"></i> Archive</li>
                        <li className="active">Barcode</li>
                    </ol>
                </section>
                <section className="content">

                    <div className="box box-default">
                        <div className="box-header with-border">
                        </div>
                        <div className="box-body" style={{"minHeight": "500px"}}>
                            <form className="form-inline" onSubmit={this.submitHandler}>
                                <div className="form-group">
                                    {/*<label htmlFor="search_item">Search Item:</label>*/}
                                    <Select
                                        style={{width:"280px"}}
                                        onChange={(e)=>{this.changeHandler(e ? e.value:0,'item')}}
                                        options={ops}
                                        placeholder="Select Category"
                                        value={this.state.item}/>
                                </div>
                                <div className="form-group" style={{paddingLeft: "10px", paddingRight: "10px"}}>
                                    {/*<label htmlFor="search_item"></label>*/}
                                    <input autoComplete={'off'} type="text" onChange={(e)=>{ this.changeHandler(e.target.value,'search_string') }} style={{width: "500px"}}
                                           name="search_string"
                                           placeholder="Enter Search Content" className="form-control"/>
                                </div>
                                <button type="button" onClick={this.getFileList}
                                        className="btn btn-primary btn-md">
                                    <i className="fa fa-search"></i> Search
                                </button>
                                &nbsp;
                                <button type="button" onClick={this.resetSearch}
                                        className="btn btn-danger btn-md">
                                    <i className="fa fa-refresh"></i> Clear
                                </button>
                            </form>
                            <br/>
                            <br/>
                            <Loader loaded={this.state.isLoaded}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <ul className="breadcrumb">
                                            <li><b>Total Files : </b><span
                                                className="label label-default">{this.state.total}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <InfiniteScroll
                                        pageStart={this.state.start}
                                        loadMore={this.loadMoreData}
                                        hasMore={this.state.hasMoreItems}
                                        loader={loader}>
                                        {items}
                                    </InfiniteScroll>
                                </div>
                            </Loader>
                        </div>
                    </div>


                    <div className="modal fade" id="modal-default1">

                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">Document Details</h4>
                                </div>

                                <div className="modal-body">
                                    <table style={{width:"100%"}}  className="table table-hover table-bordered">
                                        <thead>

                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Code</td>
                                            <td>{this.state.file_details.code?this.state.file_details.code:''}</td>
                                            <td style={{fontWeight:'bold'}}>Category</td>
                                            <td>{this.state.file_details.categoryName?this.state.file_details.categoryName:''}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontWeight:'bold'}}>Total Views</td>
                                            <td>{this.state.file_details.view_count?this.state.file_details.view_count:0}</td>
                                            <td style={{fontWeight:'bold'}}>Total Download</td>
                                            <td>{this.state.file_details.download_count?this.state.file_details.download_count:0}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.file_details.properties ?
                                                this.state.file_details.properties.map((item, index) =>
                                                    <tr key={index}>
                                                        <td style={{fontWeight:'bold'}}>{item.label}</td>
                                                        <td colSpan="3">
                                                            {item.attributes.name !='file' ? item.value:
                                                                <span>
                                                                        <ReactImageFallback
                                                                            src={item.value}
                                                                            fallbackImage={'dist/img/file_default.png'}
                                                                            initialImage={'dist/img/fallback.gif'}
                                                                            alt="cool image should be here"
                                                                            style={{height: "100px", width: "100%"}}
                                                                            className="img-rounded responsive" />
                                                                    </span>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                                :
                                                ''
                                        }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                </div>

                            </div>

                        </div>

                    </div>


                    <div className="modal fade" id="modal-default2">

                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">File Audit History</h4>
                                </div>

                                <div className="modal-body" style={{"height":"300px","overflow-y":"scroll"}}>
                                    <Loader loaded={this.state.isHistoryLoaded}>
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Log Status</th>
                                                {/*<th>User level</th>*/}
                                                <th>Log User</th>
                                                <th>Log Time</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.logHistory.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{parse(item.archive_status_name)}</td>
                                                    {/*<td>{item.user_level_name}</td>*/}
                                                    <td>{parse(item.modified_info)}</td>
                                                    <td>{item.updated_time}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </Loader>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                </div>

                            </div>

                        </div>

                    </div>


                </section>
            </div>
        );
    }
}

export default Report;
