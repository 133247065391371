
import React, { Component } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2'
import Server from './../../service/Server'
import html2canvas from 'html2canvas'
import Loader from 'react-loader';

var Barcode = require('react-barcode');
document.title ='Barcode Generatator';
const BASEURL = Server.baseUrl;

const intitialState = {
    codeList: [],
    id: '',
    quantity: '',
    isLoaded: false,
    error: false,
    errorMessage: {},
    isBtnDisabled: false,
    isUpdate: false,
}

class CodeGenerator extends Component {

    constructor(props) {
        super(props)
        this.state = intitialState;
        this.wrapper_ref = React.createRef();
    }

    getCodeList = (callBack = () => {}) => {
        axios.get(`${BASEURL}/getCodeList`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    codeList: getRes.data,
                    isLoaded: true,
                },() => {
                    callBack();
                })
            })
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getCodeList();
    }

    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            isBtnDisabled: true
        });

        if (this.state.id === '') {
            axios.post(`${BASEURL}/createCode`, { quantity: this.state.quantity })
                .then(res => {
                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.setState({
                            ...intitialState
                        });

                        this.getCodeList(()=>{
                            Swal.fire(
                                'Generated !',
                                'Code Generated Successfully',
                                'success'
                            )
    
                        });

                       
                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }
        else {
            axios.post(`${BASEURL}/updateCode`, {
                id: this.state.id,
                code: this.state.code
            })
                .then(res => {

                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.setState({
                            ...intitialState
                        });

                        this.getCodeList(()=>{
                            Swal.fire(
                                'Updated !',
                                'Code Updated Successfully',
                                'success'
                            )
    
                        });

                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            errorMessage: list,
                            error: true,
                            isBtnDisabled: false,
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        isBtnDisabled: false
                    })
                })
        }
    }


    delete = (id) => {
        var codeid = id;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {

                axios.post(`${BASEURL}/deleteCode`, { id: codeid })
                    .then(res => {
                        var getRes = res.data;
                        if (getRes.status === 'success') {
                            this.setState({
                                isLoaded : false
                            });
                            this.getCodeList(()=>{
                                Swal.fire(
                                    'Deleted !',
                                    'Code Deleted Successfully',
                                    'success'
                                )
        
                            });
                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                'Code delete is fail',
                                'warning'
                            )
                        }
                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })
            }
            else {
                Swal.fire(
                    'Cancelled!',
                    'User delete is cancel',
                    'error'
                )
            }
        })

    }

    deleteAll = (event) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {

                axios.post(`${BASEURL}/deleteAll`)
                    .then(res => {
                        var getRes = res.data;
                        if (getRes.status === 'success') {

                            this.getCodeList(()=>{
                                Swal.fire(
                                    'Deleted !',
                                    'Code Deleted Successfully',
                                    'success'
                                )
        
                            });

                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                'delete operation is fail',
                                'warning'
                            )
                        }


                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }

            else {
                Swal.fire(
                    'Cancelled!',
                    'code delete is cancel',
                    'error'
                )
            }
        })

    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

    }

    print = (e) => {
        const opt = {
            scale: 3
        }
        const elem = this.wrapper_ref.current;
        html2canvas(elem, opt).then(canvas => {
            const iframe = document.createElement('iframe')
            iframe.name = 'printf'
            iframe.id = 'printf'
            iframe.height = 0;
            iframe.width = 0;
            document.body.appendChild(iframe)

            const imgUrl = canvas.toDataURL({
                format: 'jpeg',
                quality: '5.0'
            })

            const style = `
                height:100vh;
                width:600vw;
                position:absolute;
                left:0;
                top:0;
            `;
            const url = `<img style="${style}" src="${imgUrl}"/>`;
            var newWin = window.frames["printf"];
            newWin.document.write(`<body onload="window.print()">${url}</body>`);
            newWin.document.close();

        });
    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Barcode
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-archive"></i> Archive</li>
                        <li className="active">Barcode</li>
                    </ol>
                </section>
                <section className="content">
                <Loader loaded={this.state.isLoaded}>
                    <div className="row">
                        <div className="col-lg-10 col-xs-10">

                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Code Generator</h3>
                                </div>
                                <div className="box-body">
                                    <form className="form-inline" onSubmit={this.submitHandler}>
                                        <div class="form-group">
                                            <label htmlFor="quantity">Quantity:</label>
                                            <input type="number" autoComplete="off" min="1" placeholder="Enter Quantity" className="form-control" onChange={this.changeHandler} name="quantity" value={this.state.quantity} />
                                        </div>
                                        &nbsp; <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-success">
                                            {this.state.isBtnDisabled ? 'Generateing' : 'Generate'}
                                        </button>
                                        &nbsp; <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">
                                            {this.state.isBtnDisabled ? 'Generateing' : 'Generate & Print'}
                                        </button>
                                    </form>
                                </div>
                                <div className="box-footer">
                                    {
                                        this.state.error ?
                                            <div className="alert alert-danger alert-dismissible">
                                                <strong>Error !</strong>
                                                <ul>
                                                    {

                                                        this.state.errorMessage

                                                    }
                                                </ul>
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                            </div>



                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-2">
                        </div>
                    </div>
                    
                    <div className="row">

                        <div className="col-lg-10 col-md-10 col-xs-10">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Barcode List</h3>
                                    <div style={{ float: "right" }} className="btn-group btn-group-md  btn-group-sm  btn-group-lg">
                                        <button type="button" onClick={this.deleteAll} className="btn btn-danger"><i className="fa fa-trash"> </i> Delete Unuse Code</button>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <input type="number" className="form-control input-sm" placeholder="Quantity" name="print_quantity"/>
                                        </div>
                                        <button type="button" className="btn btn-primary btn-sm"><i className="fa fa-print"> </i> Bulk Print</button>
                                    </div>

                                </div>
                                <div className="box-body table-responsive">
                                    {this.state.isLoaded ? "" : <div>Loading---</div>}
                                    <table style={{ width: "100%" }} className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "10%" }}>ID</th>
                                                <th style={{ width: "15%" }}>Code</th>
                                                <th style={{ width: "10%" }}>Total Files</th>
                                                <th style={{ width: "30%" }}>Barcode</th>
                                                <th style={{ width: "10%" }}>Status</th>
                                                <th style={{ width: "25%" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.codeList.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.code}</td>
                                                    <td>{item.total_files}</td>
                                                    <td>
                                                        <div ref={this.wrapper_ref}>
                                                            <Barcode value={item.code} />
                                                        </div>
                                                    </td>
                                                    <td><b>{item.status === null ? "Not Use" : "Used"}</b></td>
                                                    <td>
                                                        <div className="btn-group  btn-group-sm">
                                                            <button type="button" onClick={ () => { this.print(item.id) } } className="btn btn-primary"><i className="fa fa-print"></i> Print</button>
                                                            <button type="button" onClick={ () => { this.delete(item.id) } } className="btn btn-danger"><i className="fa fa-trash"></i> Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </Loader>

                </section>
            </div>
        );
    }
}

export default CodeGenerator;
