import React, {Component} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Server from './../../service/Server';
import Loader from "react-loader";
import parse from 'html-react-parser';
import ReactImageFallback from "react-image-fallback";
import Select from "../../Select";
const BASEURL = Server.baseUrl;

const FileDir = BASEURL + "/getFile/";

var fileDownload = require('js-file-download');

class FileList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: '',
            title: '',
            category_id: '',
            status: 2,
            process_status: 1,
            loaded: 0,
            isBtnDisabled: false,
            isLoaded: false,
            errorMessage: {},
            error: false,
            fileList: [],
            forms: [],
            form_title:"",
            form_content:{},
            categories: [],
            content_code:'',
            attachment_path:'',
            file_details:{},
            input_type:2,
            level_id:0,
            archive_dropdown:false,
            logHistory:[],
            isHistoryLoaded:false
        };
    }

    getDownload = (id, fileName) => {
        axios.get(`${BASEURL}/downloadFile?id=`+id)
            .then(res => {
                var getRes = res.data;
                fileDownload(getRes.data, fileName);
            })
    }
    getFileList = (callBack= ()=> {}) => {
        axios.get(`${BASEURL}/getAllFiles`)
        .then(res => {
            var getRes = res.data;
            this.setState({
                fileList: getRes.data,
                isLoaded: true,
                level_id: getRes.level_id,
                archive_dropdown: getRes.level_id == 0 ? true:false,
                attachment_path: getRes.attachment_path
            },()=>{
                callBack();
            });
        })
    }

    componentDidMount() {
        axios.get(`${BASEURL}/getCategoriesDropdown`)
            .then(res => {
                var category = res.data;
                // return;
                this.setState({
                    categories: category.data
                }, () => {
                    this.getFileList();
                });
            })
    }

    displayFile = (id) => {
        axios.get(`${BASEURL}/displayFile?id=`+id)
            .then(res => {
                var fileInfo = res.data;
                this.setState({
                    attachment_path:fileInfo.attachment_path,
                    file_details:fileInfo.data
                });
                console.log(fileInfo);
            });
    };

    // logHistory = (id) => {
    //
    // };


    getCategoryName = (category_id) => {
        let category = this.state.categories.filter(function (item) {
            return item.value === category_id
        });

        if (category.length > 0) {
            return category[0].label;
        }
        else {
            return "Unknown Category";
        }
    }

    contentUpdate = (category_id) => {
        axios.get(`${BASEURL}/getCategories?id=`+category_id)
            .then(res => {
                var category = res.data;
                if(category.data.has_form==1) {
                    let properties = JSON.parse(category.data.properties);
                    let content = {};
                    properties.forEach((item)=>{
                        content[item.name] = [];
                    });

                    this.setState({
                        category_id:category_id,
                        forms: properties,
                        form_title:category.data.title,
                        status: 2,
                        form_content:content,
                        file_id:0
                    },()=>{
                        console.log(this.state);
                    });
                }
            })

    }

    changeHandler = (event) => {
        let content = this.state.form_content;
        // console.log(content);
        if(event.target.type === "checkbox") {
            if(event.target.checked){
                content[event.target.name].push(event.target.value);
                this.setState({
                    form_content : content
                },() => {
                    console.log(this.state.form_content);
                })
            }
            else {
                let items = content[event.target.name].filter(item => item !== event.target.value);
                content[event.target.name] = items;
                this.setState({
                    form_content : content
                },()=>{
                    console.log(this.state.form_content);
                })
            }
        }
        else if(event.target.type === "file") {
            var newFile = {
                file: event.target.files[0].name,
                image: '',
                src: URL.createObjectURL(event.target.files[0])
            };

            let files = event.target.files;
            if (!files.length)
                return;
            let reader = new FileReader();
            reader.onload = (e) => {
                newFile.image = e.target.result;
            };
            reader.readAsDataURL(files[0]);
            let keyName = event.target.name;
            setTimeout(() => {
                content[keyName][0]=newFile.image;
                this.setState({
                    form_content : content
                },()=>{
                    console.log(this.state.form_content);
                });
            }, 3000);
        }
        else {
            content[event.target.name][0] = event.target.value;
            this.setState({
                form_content : content
            },()=>{
                console.log(this.state.form_content);
            });
        }
    }

    getStatusText = (id) =>{
        if(id==2) {
            return '<span class="badge badge-secondary">Not Approved</span>';
        }
        else if(id==3) {
            return '<span class="badge badge-warning">Correction</span>';
        }
        else if(id==4) {
            return '<span class="badge badge-primary">Review</span>';
        }
        else if(id==5) {
            return '<span class="badge badge-success">Approved</span>';
        }
    }

    deleteHandler = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to Delete this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
                if (result.value) {
                    axios.post(`${BASEURL}/fileDelete`, { id: id })
                        .then(res => { // then print response status

                            var getRes = res.data;
                            if (getRes.status === 'success') {

                                Swal.fire(
                                    'Deleted!',
                                    'File successfully deleted  from the list.',
                                    'success'
                                )
                                this.getFileList();

                            }
                            else {
                                Swal.fire(
                                    'Warning!',
                                    getRes.message,
                                    'warning'
                                )
                            }


                        })



                }
                else {
                    Swal.fire(
                        'Cancelled!',
                        'Delete operation has canceled',
                        'error'
                    )
                }
            }
        )
    }

    logHistory = (id) => {
        this.setState({
            logHistory :[],
            isHistoryLoaded:false

        });
        axios.get(`${BASEURL}/logHistory?id=`+id)
            .then(res => {
                var fileInfo = res.data;
                this.setState({
                    logHistory : fileInfo.data,
                    isHistoryLoaded:true

                });
            });
    }

    updateHandler = (index) => {
        let fileInfo = this.state.fileList[index];
        // console.log(fileInfo);
        axios.get(`${BASEURL}/getCategories?id=`+fileInfo.category_id)
        .then(res => {
            var category = res.data;
            if(category.data.has_form==1) {
                let properties = JSON.parse(category.data.properties);
                let content = {};
                var form_content = JSON.parse(fileInfo.content);
                console.log(form_content);
                properties.forEach((item,key)=>{
                    content[item.name] = [];
                });



                this.setState({
                    content_code:fileInfo.code,
                    category_id:category.data.id,
                    status: fileInfo.status,
                    input_type:fileInfo.input_type,
                    process_status:fileInfo.process_status==null?1:fileInfo.process_status,
                    forms: properties,
                    form_title:category.data.title,
                    form_content:form_content,
                    file_id:fileInfo.id
                },()=>{
                    console.log(this.state.form_content);
                });
            }
        })
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true
        });

        axios.post(`${BASEURL}/manualInputFileData`,{
            content: this.state.form_content,
            id:this.state.file_id,
            content_code:this.state.content_code,
            category_id:this.state.category_id,
            // process_status:this.state.process_status,
            status:this.state.status,
            input_type:this.state.input_type
        })
            .then(res => {

                let getRes = res.data;

                if(getRes.status==='success') {
                    this.setState({
                        disabled: false
                    },()=>{
                        this.modalClose();
                        this.getFileList(()=>{
                            Swal.fire(
                                'Saved!',
                                'File Content Save Successfully.',
                                'success'
                            )
                        });
                    });

                }
                else {
                    this.setState({
                        disabled: false
                    });
                    Swal.fire(
                        'Error!',
                        'something is wrong please try again',
                        'error'
                    )
                }
            })
    };

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    openModal = (id=0) => {
        this.setState({
            title:'Add New Document',
            input_type:2
        })
    }

    content_code_change = (event) => {
        this.setState({
            [event.target.name]:event.target.value
        });
    }

    render() {
        const ops = this.state.categories;
        const attachment_path = this.state.attachment_path;
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Files
                        <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-cogs"></i> Archive</li>
                        <li className="active">Files</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">File List</h3>
                                    <button onClick={(e) => {this.openModal(0)}} style={{ float: "right" }} type="button" className="btn btn-primary btn-md" data-toggle="modal" data-target="#modal-default">
                                        <i className="fa fa-plus"></i> New Document
                                    </button>
                                </div>
                                <div className="box-body">
                                    <Loader loaded={this.state.isLoaded}>
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>Code</th>
                                                <th>files</th>
                                                <th>Archive Type</th>
                                                <th>Category</th>
                                                {/*<th>File Level</th>*/}
                                                {/*<th>Process Status</th>*/}
                                                <th>Archive</th>
                                                <th>Last Modified</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.fileList.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.code}</td>
                                                    <td>
                                                        <ReactImageFallback
                                                            src={item.file_path}
                                                            fallbackImage={'dist/img/file_default.png'}
                                                            initialImage={'dist/img/fallback.gif'}
                                                            alt="cool image should be here"
                                                            style={{height: "80px", width: "100px"}}
                                                            className="img-rounded responsive" />
                                                    </td>
                                                    <td>{item.archive_type_name}</td>
                                                    <td>{item.categoryName}</td>
                                                    {/*<td>{item.user_level_name}</td>*/}
                                                    {/*<td>{ parse(item.process_status_name)}</td>*/}
                                                    <td>{ parse(item.archive_status_name) }</td>
                                                    <td>{ parse(item.last_modified) }</td>
                                                    <td>

                                                        <div className="dropdown">
                                                            <button className="btn btn-default btn-sm dropdown-toggle"
                                                                    type="button" data-toggle="dropdown">Action
                                                                <span className="caret"></span></button>
                                                            <ul className="dropdown-menu">
                                                                <li><a href="javascript:void(0);" onClick={() => this.updateHandler(index)} data-toggle="modal" data-target="#modal-default"><i className="fa fa-edit"></i>Edit & View</a></li>
                                                                <li><a href="javascript:void(0);" data-toggle="modal" data-target="#modal-default1"
                                                                       onClick={() => this.displayFile(item.id)}><i className="fa fa-eye"></i>View</a></li>

                                                                {
                                                                    item.input_type==1 ?
                                                                        <li><a href="javascript:void(0);" onClick={() => this.getDownload(item.id, item.file_name)}><i className="fa fa-download"></i>Download</a></li>
                                                                        :""
                                                                }

                                                                <li><a href="javascript:void(0);" data-toggle="modal" data-target="#modal-default2" onClick={() => this.logHistory(item.id)}><i className="fa fa-history"></i>Log History</a></li>
                                                                <li className="divider"></li>
                                                                <li><a href="javascript:void(0);" onClick={() => this.deleteHandler(item.id)}><i className="fa fa-trash"></i>Delete</a></li>
                                                            </ul>
                                                        </div>

                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </Loader>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">
                            <form className="form-horizontal"  onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">{this.state.title}</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">
                                            <div className="form-group">
                                                <label className="control-label col-sm-1" htmlFor="email">Code:</label>
                                                <div className="col-sm-4">
                                                    <input type="text" value={this.state.content_code} name="content_code" onChange={this.content_code_change} className="form-control" id="email"
                                                           placeholder="Enter File Code"/>
                                                </div>
                                                <label className="control-label col-sm-2" htmlFor="email">Category:</label>
                                                <div className="col-sm-5">
                                                    <Select
                                                        onChange={(e)=>{this.contentUpdate(e.value)}}
                                                        options={ops}
                                                        placeholder="Select Category"
                                                        value={this.state.category_id} />
                                                </div>
                                            </div>
                                            {
                                                this.state.forms.map((item, index) =>
                                                    <div key={index} className="form-group">
                                                        <label htmlFor="name">{item.label}</label>
                                                        {
                                                            item.attributes.name === "select" ?
                                                                <select onChange={this.changeHandler} value={this.state.form_content[item.name][0]} name={item.name} className="form-control">
                                                                    <option value="">Select Gender</option>
                                                                    {
                                                                        item.attributes.hasoption===true && item.attributes.options.length > 0 ?
                                                                            item.attributes.options.map((option,optionKey) =>
                                                                                <option key={optionKey} value={option.value}>{option.name}</option>
                                                                            )
                                                                            :
                                                                            ""
                                                                    }
                                                                </select>
                                                                :
                                                                (
                                                                    item.attributes.name === "textarea" ?
                                                                        <textarea onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name}></textarea>
                                                                        :
                                                                        (
                                                                            item.attributes.name === "checkbox" ?
                                                                                <div>
                                                                                    {
                                                                                        item.attributes.options.map((option,optionKey)=>
                                                                                            <div key={optionKey}>
                                                                                                <span>{option.name}</span> <input type={item.attributes.name} name={item.name} checked={this.state.form_content[item.name].indexOf(option.value) >= 0  ? true: false } value={option.value} onChange={this.changeHandler}/>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                (
                                                                                item.attributes.name=='file' ?
                                                                                    (
                                                                                        <span>
                                                                                            <ReactImageFallback
                                                                                                src={this.state.form_content[item.name][0]}
                                                                                                fallbackImage={'dist/img/file_default.png'}
                                                                                                initialImage={'dist/img/fallback.gif'}
                                                                                                alt="cool image should be here"
                                                                                                style={{height: "100px", width: "100%"}}
                                                                                                className="img-rounded responsive" />
                                                                                            <input type={item.attributes.name} onChange={this.changeHandler} className="form-control" value={''} name={item.name} placeholder="Enter Full Name" />
                                                                                        </span>

                                                                                    )

                                                                                :
                                                                                <input type={item.attributes.name} onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name} placeholder="Enter Full Name" />
                                                                                )


                                                                        )
                                                                )
                                                        }

                                                    </div>
                                                )
                                            }

                                            {/*<div className="form-group">*/}
                                            {/*    <label>Process Status:</label>*/}
                                            {/*    <select onChange={this.content_code_change} value={this.state.process_status} name='process_status' className="form-control">*/}
                                            {/*        <option value="1">On Process</option>*/}
                                            {/*        <option value="3">Approved</option>*/}
                                            {/*        <option value="2">Denied</option>*/}
                                            {/*    </select>*/}
                                            {/*</div>*/}
                                            <div className="form-group" style={{"display":this.state.archive_dropdown ? 'block':'none'}}>
                                                <label htmlFor="email">Archive Status:</label>
                                                <select onChange={this.content_code_change} value={this.state.status} name='status' className="form-control">
                                                    <option value="2">Not Archived</option>
                                                    <option value="3">Archived</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.disabled} className="btn btn-primary">{this.state.disabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>


                    <div className="modal fade" id="modal-default1">

                        <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">Document Details</h4>
                                    </div>

                                    <div className="modal-body">
                                        <table style={{width:"100%"}}  className="table table-hover table-bordered">
                                            <thead>

                                                <tr>
                                                    <td style={{fontWeight:'bold'}}>Code</td>
                                                    <td>{this.state.file_details.code?this.state.file_details.code:''}</td>
                                                    <td style={{fontWeight:'bold'}}>Category</td>
                                                    <td>{this.state.file_details.categoryName?this.state.file_details.categoryName:''}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'bold'}}>Total Views</td>
                                                    <td>{this.state.file_details.view_count?this.state.file_details.view_count:0}</td>
                                                    <td style={{fontWeight:'bold'}}>Total Download</td>
                                                    <td>{this.state.file_details.download_count?this.state.file_details.download_count:0}</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.file_details.properties ?
                                                    this.state.file_details.properties.map((item, index) =>
                                                        <tr key={index}>
                                                            <td style={{fontWeight:'bold'}}>{item.label}</td>
                                                            <td colSpan="3">
                                                                {item.attributes.name !='file' ? item.value:
                                                                    <span>
                                                                        <ReactImageFallback
                                                                            src={item.value}
                                                                            fallbackImage={'dist/img/file_default.png'}
                                                                            initialImage={'dist/img/fallback.gif'}
                                                                            alt="cool image should be here"
                                                                            style={{height: "100px", width: "100%"}}
                                                                            className="img-rounded responsive" />
                                                                    </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                    :
                                                    ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                    </div>

                                </div>

                        </div>

                    </div>


                    <div className="modal fade" id="modal-default2">

                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">File Audit History</h4>
                                </div>

                                <div className="modal-body" style={{"height":"300px","overflow-y":"scroll"}}>
                                    <Loader loaded={this.state.isHistoryLoaded}>
                                        <table className="table table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Log Status</th>
                                                    {/*<th>User level</th>*/}
                                                    <th>Log User</th>
                                                    <th>Log Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.logHistory.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{parse(item.archive_status_name)}</td>
                                                    {/*<td>{item.user_level_name}</td>*/}
                                                    <td>{parse(item.modified_info)}</td>
                                                    <td>{item.updated_time}</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </Loader>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                </div>

                            </div>

                        </div>

                    </div>


                </section>
            </div>






        );
    }
}

export default FileList;
