// SideBar.js
import {  NavLink  } from 'react-router-dom';
import React, { Component } from 'react';

class SideBar extends Component {
    render() {
        return (
            <aside className="main-sidebar">
                <section className="sidebar">
                    <div className="user-panel">
                        <div className="pull-left image">
                            <img src="dist/img/user2-160x160.jpg" className="img-circle" alt="User" />
                        </div>
                        <div className="pull-left info">
                            <p>Alexander Pierce</p>
                            <NavLink to="/"><i className="fa fa-circle text-success"></i> Online</NavLink>
                        </div>
                    </div>
                    <form action="#" method="get" className="sidebar-form">
                        <div className="input-group">
                            <input type="text" name="q" className="form-control" placeholder="Search..." />
                            <span className="input-group-btn">
                                <button type="submit" name="search" id="search-btn" className="btn btn-flat"><i className="fa fa-search"></i>
                                </button>
                            </span>
                        </div>
                    </form>
                    <ul className="sidebar-menu" data-widget="tree">
                        <li className="header">MAIN NAVIGATION</li>
                        <li>
                            <NavLink  to="/">
                                <i className="fa fa-dashboard"></i> <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="treeview">
                            <NavLink to="/">
                                <i className="fa fa-archive"></i>
                                <span>General Archive</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </NavLink>
                            <ul className="treeview-menu">
                                <li><NavLink  to="/category"><i className="fa fa-circle-o"></i> Category</NavLink></li>
                                <li><NavLink to="/codeGenerator"><i className="fa fa-circle-o"></i> Barcode</NavLink></li>
                                {/*<li><NavLink to="/fileEntry"><i className="fa fa-circle-o"></i> Entry</NavLink></li>*/}
                                <li><NavLink to="/manageFile"><i className="fa fa-circle-o"></i> Scan</NavLink></li>
                                <li><NavLink to="/file_content"><i className="fa fa-circle-o"></i> File Content</NavLink></li>
                                <li><NavLink to="/file_list"><i className="fa fa-circle-o"></i> File Process</NavLink></li>
                                <li><NavLink to="/trash_list"><i className="fa fa-circle-o"></i> Trash List</NavLink></li>
                                <li><NavLink to="/report"><i className="fa fa-circle-o"></i> Archive</NavLink></li>
                            </ul>
                        </li>
                        <li className="treeview">
                            <NavLink to="/">
                                <i className="fa fa-archive"></i>
                                <span>Process Archive</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </NavLink>
                            <ul className="treeview-menu">
                                <li><NavLink to="/process_file_list"><i className="fa fa-circle-o"></i> Processing</NavLink></li>
                                <li><NavLink to="/process_report"><i className="fa fa-circle-o"></i> Archive</NavLink></li>
                                <li><NavLink to="/process_temp_file_list"><i className="fa fa-circle-o"></i> Trash List</NavLink></li>
                                <li><NavLink to="/process_settings"><i className="fa fa-circle-o"></i> Process Configuration</NavLink></li>
                            </ul>
                        </li>
                        <li className="treeview">
                            <NavLink to="/">
                                <i className="fa fa-cogs"></i>
                                <span>Configuration</span>
                                <span className="pull-right-container">
                                    <i className="fa fa-angle-left pull-right"></i>
                                </span>
                            </NavLink>
                            <ul className="treeview-menu">
                                <li><NavLink to="/user"><i className="fa fa-circle-o"></i> User</NavLink></li>
                                <li><NavLink to="/department"><i className="fa fa-circle-o"></i> Department</NavLink></li>
                                <li><NavLink to="/designation"><i className="fa fa-circle-o"></i> Designation</NavLink></li>
                                <li><NavLink to="/general_setting"><i className="fa fa-circle-o"></i> General Setting</NavLink></li>


                            </ul>
                        </li>
                        
                    </ul>
                </section>
            </aside>
        )
    }
}

export default SideBar;