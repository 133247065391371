
import React from 'react'
import Login from './auth/Login'
import {Route} from 'react-router-dom'
class PublicComponnent extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            redirect:false
        }
        
    }
    render() {
        return(
            <Route exact path="/login" component={() =>
                <Login login={this.props.login} />
              } />
        );
    }
}

export default PublicComponnent;
