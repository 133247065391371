import React from 'react'
import { BrowserRouter as Router, HashRouter } from 'react-router-dom'
import Auth from './service/Auth'
import PublicComponnent from './componnent/PublicComponent'
import PrivateComponnent from './componnent/PrivateComponnent'
import history from './History'
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLogin: Auth.isLogin
    };
  }

  login = (isLogin) => {
    if (isLogin) {
      window.location = window.location.origin;
    }
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL} history={history}>
          <PrivateComponnent />
          <PublicComponnent login={this.login}/>
      </Router>
    );
  }

}

export default App;
