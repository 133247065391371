
import React, {Component} from 'react'
import Auth from './../../service/Auth'
import {Redirect} from 'react-router-dom'
class Logout extends Component {
    constructor(props){
        super(props);
        this.state = {redirect:false}
        Auth.logout(()=>{
            this.setState({redirect:false})
        })
    }
    
    render() {
        if(this.state.redirect){
            return <Redirect to='/login'/>
        }
        else {
            return <h1>Loging Out</h1>
        }
    }
}

export default Logout;
