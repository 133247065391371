import React, { Component } from 'react';
import Loader from 'react-loader';
import axios from 'axios';
import Swal from 'sweetalert2';
import Server from './../../service/Server';




document.title = "Category";
const BASEURL = Server.baseUrl;

const intitialState = {
    title: '',
    ctgsorting: '',
    id: '',
    temp_properties: [],
    label: '',
    name: '',
    sorting: '',
    type: { name: '', hasoption: false, options: [] },
    option_name: '',
    option_value: '',
    isLoaded: false,
    error: false,
    error_message: {},
    categories: [],
    is_btn_disabled: false,
    showForm:false,
    parent_id:0,
};

class Category extends Component {

    constructor(props) {
        super(props)
        this.myForm = React.createRef()
        this.state = intitialState;
        document.title = "Category";
    }

    openModal = (id=0) => {
        this.setState({
            title: '',
            ctgsorting: '',
            id: '',
            temp_properties: [],
            label: '',
            name: '',
            sorting: '',
            type: { name: '', hasoption: false, options: [] },
            option_name: '',
            option_value: '',
            error: false,
            showForm:false,
            parent_id:id,
        });
    }

    getCategories = (calllBack =  () => {}) => {
        axios.get(`${BASEURL}/getCategories`)
        .then(response => {
            var getRes = response.data;
            this.setState({
                categories: getRes.data,
                isLoaded: true,
            },() => {
                calllBack();
            })
        })
        .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getCategories();
    }

    update = (index) => {
        axios.get(`${BASEURL}/getCategories`, {
            params: {
                id: index
            }
        })
            .then(response => {
                var getRes = response.data;
                var category = getRes.data;
                // console.log(category);
                // return;
                this.setState({
                    title: category.title,
                    ctgsorting: category.sorting,
                    id: category.id,
                    temp_properties: category.properties==null ? [] : JSON.parse(category.properties),
                    error: false,
                    showForm: category.has_form==0 ? false:true,
                });

                this.resetCurrentProperties();

            })
            .catch(error => console.log(error));
    }

    delete = (id) => {
        var categoryId = id;
        Swal.fire({
            title: 'Are you sure?',
            text: "you wnat to delete the category",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.value) {

                axios.post(`${BASEURL}/categoryDelete`, { id: categoryId })
                    .then(res => {
                        var getRes = res.data;
                        if (getRes.status === 'success') {
                            this.setState({
                                isLoaded : false
                            });
                            this.getCategories(()=>{
                                Swal.fire(
                                    'Deleted!',
                                    'Category Deleted Successfully',
                                    'success'
                                )
                            });


                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                'Category delete is fail',
                                'warning'
                            )
                        }


                    })
                    .catch(error => {
                        this.setState({
                            error: true,
                        })
                    })


            }

            else {
                Swal.fire(
                    'Cancelled!',
                    'Category delete is cancel',
                    'error'
                )
            }
        })

    }

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    submitHandler = (event) => {

        event.preventDefault();

        var categoryData = {
            "title": this.state.title,
            "sorting": this.state.ctgsorting,
            "has_form": this.state.showForm,
            "parent_id": this.state.parent_id,
            "id": this.state.id,
            "properties": this.state.temp_properties
        };

        // console.log(categoryData);
        // return false;

        this.setState({
            is_btn_disabled: true
        });

        if (this.state.id === '') {
            axios.post(`${BASEURL}/categoryCreate`, categoryData)
                .then(res => {
                    var getRes = res.data;
                    // console.log(getRes);
                    // return false;
                    if (getRes.status === 'success') {
                        this.myForm.current.reset();
                        this.setState({
                            ...intitialState
                        });

                        this.getCategories(()=>{
                            Swal.fire(
                                'Created!',
                                'Category Created Successfully',
                                'success'
                            )
                        });

                        this.modalClose();


                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            error_message: list,
                            error: true,
                            is_btn_disabled: false
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        is_btn_disabled: false
                    })
                })
        }

        else {
            axios.post(`${BASEURL}/categoryUpdate`, categoryData)
                .then(res => {

                    var getRes = res.data;
                    if (getRes.status === 'success') {

                        this.myForm.current.reset();
                        this.setState({
                            ...intitialState
                        });

                        this.modalClose();
                        this.getCategories(()=>{
                            Swal.fire(
                                'Updated!',
                                'Category Updated Successfully',
                                'success'
                            )
                        });
                        

                    }
                    else {
                        var errors = JSON.parse(getRes.message);
                        var list = Object.keys(errors).map(function (key) {
                            return <li key={key}>{errors[key][0]}</li>
                        });
                        this.setState({
                            error_message: list,
                            error: true,
                            is_btn_disabled: false,
                        });
                    }


                })
                .catch(error => {
                    this.setState({
                        error: true,
                        is_btn_disabled: false
                    })
                })
        }
    }

    changeHandler = (event) => {

        if (event.target.validity.valid) {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
        else {
            event.preventDefault();
        }
    }

    typeChangeHandler = (event) => {
        let type = event.target.value;
        if (type === 'select'  || type === 'checkbox') {
            this.setState({
                type: {
                    name: type,
                    hasoption: true,
                    options: []
                }
            });
        }
        else {
            this.setState({
                type: {
                    name: type,
                    hasoption: false,
                    options: []
                }
            });
        }
    }


    pushOption(event) {

        if (this.state.option_name === '' || this.state.option_value === '') {
            return;
        }
        else {
            let currenttypeOptions = this.state.type.options;
            currenttypeOptions.push({
                name: this.state.option_name,
                value: this.state.option_value
            });
            this.setState(
                {
                    option_name: '',
                    option_value: '',
                    type: {
                        name: this.state.type.name,
                        hasoption: true,
                        options: currenttypeOptions
                    }
                }

                , () => {
                    console.log(this.state);
                });
        }
    }

    popOptions(index) {
        let currentoptions = this.state.type.options;
        currentoptions.splice(index, 1);
        this.setState({
            type: {
                hasoption: true,
                name: this.state.type.name,
                options: currentoptions
            }
        });
    }


    resetCurrentProperties() {
        this.setState({
            label: '',
            name: '',
            sorting: '',
            type: { name: '', hasoption: false, options: [] }
        });
    }

    addProperties = (event) => {
        let currenttemp_properties = this.state.temp_properties;
        currenttemp_properties.push(
            {
                label: this.state.label,
                name: 'name_'+ Math.floor(Math.random()*1000) + 1,
                sorting: this.state.sorting,
                attributes: this.state.type

            }
        );

        this.setState(
            {
                temp_properties: currenttemp_properties
            },
            () => {
                this.resetCurrentProperties();
            });
    }

    removeProperties(index) {
        let currenttemp_properties = this.state.temp_properties;
        currenttemp_properties.splice(index, 1);
        this.setState({
            temp_properties: currenttemp_properties
        });
    }

    renderChild = (child) => {
        if (child.sub.length > 0) {
            return (
                <li>
                    {child.title}  &nbsp;&nbsp;[<i title="Add Subcategory" data-toggle="modal" data-target="#modal-default" style={{cursor:"pointer"}} className="fa fa-plus" onClick={(e) => {this.openModal(child.id)}}></i>] [<i title="Edit Category" style={{cursor:"pointer"}} data-toggle="modal" data-target="#modal-default" className="fa fa-pencil" onClick={(e) => { this.update(child.id) }}></i>] [<i title="Remove Category" style={{cursor:"pointer"}} className="fa fa-remove" onClick={(e) => {this.delete(child.id)}}></i>]
                    <ul style={{lineHeight:"20px"}}>
                        {child.sub.map((item,index) => {
                            return this.renderChild(item);
                        })}
                    </ul>
                </li>
            );
        }
        else if (child.title) {
            return (
                <li>
                    {child.title}
                            &nbsp;&nbsp;[<i title="Add Subcategory" data-toggle="modal" data-target="#modal-default" style={{cursor:"pointer"}} className="fa fa-plus" onClick={(e) => {this.openModal(child.id)}}></i>] [<i title="Edit Category" style={{cursor:"pointer"}} data-toggle="modal" data-target="#modal-default" className="fa fa-pencil" onClick={(e) => { this.update(child.id) }}></i>] [<i title="Remove Category" style={{cursor:"pointer"}} className="fa fa-remove" onClick={(e) => {this.delete(child.id)}}></i>]
                </li>
            );
        }
        return null;
    }

    handleCheckBoxClick = (id,checked) => {
        if(checked){
            this.setState({
                showForm:true
            })
        }
        else {
            this.setState({
                showForm:false
            })
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Category
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-archive"></i> Archive</li>
                        <li className="active">Category</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>
                        <div className="row">
                            <div className="col-xs-12 col-md-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Category List</h3>
                                        <button onClick={(e) => {this.openModal(0)}} style={{ float: "right" }} type="button" className="btn btn-primary btn-md" data-toggle="modal" data-target="#modal-default">
                                            <i className="fa fa-plus"></i> New Category
                                        </button>
                                    </div>
                                    <div className="box-body">
                                        <table style={{ width: "100%" }} className="table table-bordered table-hover">
                                            <tbody>
                                                {
                                                    this.state.categories.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <ul style={{lineHeight:"20px"}}>
                                                                    {this.renderChild(item)}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Loader>
                    <div className="modal fade in" data-keyboard="false" data-backdrop="static" id="modal-default">

                        <div className="modal-dialog modal-lg">

                            <form ref={this.myForm} className="form-horizontal" role="form" onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">File Category</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">


                                            {/* <div className="form-group">
                                                        <label htmlFor="name">Category Name</label>
                                                        <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.title} name="title" placeholder="Enter Category Name"/>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="sorting">Sorting</label>
                                                        <input type="text" onChange={this.changeHandler} className="form-control" value={this.state.sorting} name="sorting" placeholder="Enter Sorting"/>
                                                    </div> */}


                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="name">Category Name:</label>
                                                <div className="col-sm-7 col-md-7">
                                                    <input type="text" autoComplete="off" name="title" onChange={this.changeHandler} value={this.state.title} className="form-control" id="name" placeholder="Category Name" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="sorting">Sorting:</label>
                                                <div className="col-sm-7 col-md-7">
                                                    <input type="text" autoComplete="off" name="ctgsorting" pattern="[0-9]*" onChange={this.changeHandler} value={this.state.ctgsorting} className="form-control" id="sorting" placeholder="Sorting allwod only integer" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="sorting">Has input form:</label>
                                                <div className="col-sm-7 col-md-7">
                                                    <input style={{paddingTop:"5px"}} type="checkbox" autoComplete="off" name="has_form" checked={this.state.showForm ? 'checked':false}  onChange={(e) => {this.handleCheckBoxClick(1, e.target.checked)}} value={'1'} id="has_form" />
                                                </div>
                                            </div>
                                            <div style={{ display:this.state.showForm ? 'block' :'none' }}>
                                            <p>File Information Setup</p>
                                            <hr style={{ "marginTop": "0px" }} />
                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="label">Field Name:</label>
                                                <div className="col-sm-4 col-md-4">
                                                    <input type="text" autoComplete="off" onChange={this.changeHandler} value={this.state.label} className="form-control input-sm" name="label" placeholder="Field Label" />
                                                </div>
                                            </div>
                                            {/*<div className="form-group">*/}
                                            {/*    <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="name">Field Name:</label>*/}
                                            {/*    <div className="col-sm-4 col-md-4">*/}
                                            {/*        <input type="text" autoCapitalize="none" pattern="[^' ']+" autoComplete="off" onChange={this.changeHandler} value={this.state.name.toLowerCase()} className="form-control input-sm" name="name" placeholder="Field Name" />*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="name">Sorting:</label>
                                                <div className="col-sm-4 col-md-4">
                                                    <input type="text" autoComplete="off" pattern="[0-9]*" onChange={this.changeHandler} value={this.state.sorting} className="form-control input-sm" name="sorting" id="sorting" placeholder="Field Position allowd only integer" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="Field Type">Field Type:</label>
                                                <div className="col-sm-4 col-md-4">
                                                    <select className="form-control input-sm" value={this.state.type.name} onChange={this.typeChangeHandler} name="type.name">
                                                        <option value="">Field Type</option>
                                                        <option value="text">Text</option>
                                                        <option value="textarea">Textarea</option>
                                                        <option value="select">Select</option>
                                                        {/* <option value="radio">Radio</option> */}
                                                        <option value="checkbox">Checkbox</option>
                                                        <option value="file">File</option>
                                                        <option value="date">Date</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {this.state.type.hasoption === true ?
                                                <div className="form-group">
                                                    <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="option_name">Options:</label>
                                                    <div className="col-sm-3 col-md-3">
                                                        <input type="text" autoComplete="off" className="form-control input-sm" value={this.state.option_name} onChange={this.changeHandler} name="option_name" id="labelName" placeholder="Option Name" />
                                                    </div>
                                                    <div className="col-sm-3 col-md-3">
                                                        <input type="text" autoComplete="off" className="form-control input-sm" value={this.state.option_value} onChange={this.changeHandler} name="option_value" id="labelName" placeholder="Option Value" />
                                                    </div>
                                                    <div className="col-sm-1 col-md-1">
                                                        <button type="button" onClick={this.pushOption.bind(this)} className="btn btn-info btn-sm"><i className="fa fa-plus"></i></button>
                                                    </div>
                                                </div>



                                                :
                                                ''
                                            }

                                            {
                                                this.state.type.options.map((item, index) =>
                                                    <div key={index} className="form-group">
                                                        <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="option_name">Field Options:</label>
                                                        <div className="col-sm-3 col-md-3">
                                                            <input type="text" readOnly autoComplete="off" className="form-control input-sm" value={item.name} placeholder="Option Name" />
                                                        </div>
                                                        <div className="col-sm-3 col-md-3">
                                                            <input type="text" readOnly autoComplete="off" className="form-control input-sm" value={item.value} placeholder="Option Value" />
                                                        </div>
                                                        <div className="col-sm-1 col-md-1">
                                                            <button type="button" onClick={() => this.popOptions(index)} className="btn btn-danger btn-sm"><i className="fa fa-minus"></i></button>
                                                        </div>
                                                    </div>
                                                )
                                            }


                                            <div className="form-group">
                                                <label className="control-label col-sm-2 col-md-2 col-lg-2" htmlFor="name">
                                                    <button type="button" onClick={() => this.addProperties()} className="btn btn-success btn-sm">Add More</button>
                                                </label>
                                                <div className="col-sm-3 col-md-3">
                                                </div>
                                            </div>

                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Label</th>
                                                        {/*<th>Name</th>*/}
                                                        <th>Sorting</th>
                                                        <th>Type</th>
                                                        <th>Has Option</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {

                                                        this.state.temp_properties.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{item.label}</td>
                                                                {/*<td>{item.name}</td>*/}
                                                                <td>{item.sorting}</td>
                                                                <td>{item.attributes.name}</td>
                                                                <td>{item.attributes.hasoption ? 'Yes' : 'No'}</td>
                                                                <td>
                                                                    <button type="button" onClick={() => this.removeProperties(index)} className="btn btn-danger btn-xs"> <i className="fa fa-trash"></i> Remove</button>
                                                                </td>
                                                            </tr>
                                                        )

                                                    }
                                                </tbody>
                                            </table>

                                        </div>

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.error_message

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <div className="row">
                                            <div className="col-md-7 col-lg-7">
                                                <button type="submit" disabled={this.state.is_btn_disabled} className="btn btn-primary"> {this.state.is_btn_disabled ? 'Saveing' : 'Save'} </button>
                                                <button type="button" id="closeBtn" className="btn btn-danger pull-right" data-dismiss="modal"> Discard</button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>

                </section>
            </div>
        );

    }
}

export default Category;
