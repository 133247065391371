
import React from 'react'
import Department from './../componnent/configuration/Department';
import Designation from './../componnent/configuration/Designation';
import UserRegistration from './../componnent/configuration/UserRegistration';
import ProcessArchive from './../componnent/configuration/ProcessArchive';
import GeneralSetting from './../componnent/configuration/GeneralSetting';
import PrivateRoute from './..//componnent/auth/PrivateRoute';
class Configuration  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            globalData:{
                category:[]
            }
        }
    }

    render() {
        return (
            <>
               <PrivateRoute exact path="/department" component={Department}/>
               <PrivateRoute exact path="/designation" component={Designation}/>
               <PrivateRoute exact path="/general_setting" component={GeneralSetting}/>
               <PrivateRoute exact path="/user"
                component={() =>
                    <UserRegistration/>
                }
                
                />
                <PrivateRoute exact path="/process_settings"
                              component={() =>
                                  <ProcessArchive/>
                              }

                />
            </>
        );
    }
}

export default Configuration;
