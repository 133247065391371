import React, { Component } from 'react';
import axios from 'axios'
import Swal from 'sweetalert2'
// import scanner from 'scanner-js'
import Server from './../../service/Server'
// import Server from 'scanner'
import Loader from 'react-loader';
document.title = "File Entry";
const BASEURL = Server.baseUrl;

class FileEntry extends Component {
    constructor(props) {
        super(props);
        this.myForm = React.createRef();
        this.state = {
            attachtedFiles: [],
            loaded: 0,
            isBtnDisabled: false,
            isLoaded: false,
            errorMessage: {},
            error: false,
            bar_code:'',
            imagesScanned:[]

        }
    }

    componentDidMount() {
        this.setState({
            isLoaded :true
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        const formData = { filesInfo: this.state.attachtedFiles,bar_code:this.state.bar_code };
        this.setState({
            isBtnDisabled: true
        });
        axios.post(`${BASEURL}/fileEntry`, formData, {
            onUploadProgress: ProgressEvent => {
                // this.setState({
                //     loaded: Math.round((ProgressEvent.loaded / ProgressEvent.total * 100)),
                // })
            },
        })
            .then(res => { // then print response status

                var getRes = res.data;
                if (getRes.status === 'success') {

                    this.setState({
                        isBtnDisabled: false,
                        errorMessage: {},
                        error: false,
                        attachtedFiles: [],
                        loaded: 0,
                    });
                    this.myForm.current.reset();
                    Swal.fire(
                        'Uploaded!',
                        'File successfully Uploaded.',
                        'success'
                    )

                }
                else {
                    let errors = JSON.parse(getRes.message);
                    let list = Object.keys(errors).map(function (key) {
                        return <li key={key}>{errors[key][0]}</li>
                    });
                    this.setState({
                        errorMessage: list,
                        error: true,
                        isBtnDisabled: false,
                        loaded: 0
                    });
                }


            })
    }

    changeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

    }

    removeFile = (index) => {
        var removeIndex = index;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to remove this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {

                var currentAttachFiles = this.state.attachtedFiles;
                currentAttachFiles.splice(removeIndex, 1);
                this.setState({
                    attachtedFiles: currentAttachFiles
                });
                Swal.fire(
                    'Removed!',
                    'Remove successfully from the list.',
                    'success'
                )
            }
            else {
                Swal.fire(
                    'Cancelled!',
                    'remove is cancel',
                    'error'
                )
            }
        }
        )

    }

    addCode = (event) => {
        this.setState({
            code: event.target.value,
            title: '',
            description: '',
            category_id: '',
            loaded: 0,
            metaTag: '',
        });
    }

    onChangeHandler = (event) => {

        var newFile = {
            file: event.target.files[0].name,
            // code: this.state.code,
            // title: this.state.title,
            // description: this.state.description,
            // category_id: this.state.category_id,
            // metaTag: this.state.metaTag,
            image: '',
            src: URL.createObjectURL(event.target.files[0])
        };

        let files = event.target.files || event.dataTransfer.files;
        if (!files.length)
            return;
        let reader = new FileReader();
        reader.onload = (e) => {
            newFile.image = e.target.result;
        };
        reader.readAsDataURL(files[0]);

        var currentAttachment = this.state.attachtedFiles;
        currentAttachment.push(newFile);
        this.setState({
            attachtedFiles: currentAttachment,
        })
    }

    attachedFileCheck = (code) => {
        var file = this.state.attachtedFiles.find(item => item.code === code);
        if (file) {
            return true;
        }
        else {
            return false;
        }
    }

    getCategoryName = (category_id) => {
        var category = this.categories.find(item => item.id == category_id);
        if (category) {
            return category.title;
        }
        else {
            return null;
        }
    }

    deleteHandler = (fileId) => {
        return;
        var id = fileId;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to Delete this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                axios.post(`${BASEURL}/fileDelete`, { id: id })
                    .then(res => { // then print response status

                        var getRes = res.data;
                        if (getRes.status === 'success') {

                            Swal.fire(
                                'Deleted!',
                                'File successfully deleted  from the list.',
                                'success'
                            )

                            this.getPendingCodeList();
                            this.getFileList();

                        }
                        else {
                            Swal.fire(
                                'Warning!',
                                getRes.message,
                                'warning'
                            )
                        }


                    })



            }
            else {
                Swal.fire(
                    'Cancelled!',
                    'Delete operation has canceled',
                    'error'
                )
            }
        }
        )
    }

    updateHandler = (fileIndex) => {

        var fileInfo = this.state.fileList[fileIndex];
        this.setState({
            id: fileInfo.id,
            update_code: fileInfo.code,
            update_title: fileInfo.title,
            update_description: fileInfo.description,
            update_category_id: fileInfo.category_id,
            update_metaTag: fileInfo.meta_tag,
            update_image: '',


            isBtnDisabled: false,
            loaded: 0,
            errorMessage: {},
            error: false,
        });
    }

    updateFile = (event) => {
        event.preventDefault();
        this.setState({
            isBtnDisabled: true
        });
        var formData = {
            id: this.state.id,
            code: this.state.update_code,
            description: this.state.update_description,
            category_id: this.state.update_category_id,
            title: this.state.update_title,
            metaTag: this.state.update_metaTag,
            image: this.state.update_image
        };

        axios.post(`${BASEURL}/fileUpdate`, formData)
            .then(res => { // then print response status

                var getRes = res.data;
                if (getRes.status === 'success') {

                    this.setState({
                        isBtnDisabled: false,
                        loaded: 0,
                        errorMessage: {},
                        error: false,
                    });

                    this.getPendingCodeList();
                    this.getFileList();
                    this.modalClose();
                    Swal.fire(
                        'Updated!',
                        'File successfully Updated.',
                        'success'
                    )
                }
                else {
                    var errors = JSON.parse(getRes.message);
                    var list = Object.keys(errors).map(function (key) {
                        return <li key={key}>{errors[key][0]}</li>
                    });
                    this.setState({
                        errorMessage: list,
                        error: true,
                        isBtnDisabled: false,
                        loaded: 0
                    });
                }


            })
    }

    updateChangeHandler = (event) => {

        if (
            this.state.update_code === '' || this.state.update_title === '' || this.state.update_category_id === ''
            || this.state.update_description === '' || this.state.update_metaTag === ''
        ) {
            alert('please fill up all required data');
            return;
        }
        var files = event.target.files || event.dataTransfer.files;
        if (!files.length)
            return;
        var reader = new FileReader();
        reader.onload = (e) => {
            this.state.update_image = e.target.result;
        };
        reader.readAsDataURL(files[0]);



    }

    modalClose = () => {
        var el = document.getElementById('closeBtn');
        if (el.onclick) {
            el.onclick();
        } else if (el.click) {
            el.click();
        }
    }

    // scanToJpg = () => {
    //     scanner.scan(this.displayImagesOnPage,
    //         {
    //             "use_asprise_dialog": true, // Whether to use Asprise Scanning Dialog
    //             "show_scanner_ui": false, // Whether scanner UI should be shown
    //             "twain_cap_setting": { // Optional scanning settings
    //                 "ICAP_PIXELTYPE": "TWPT_RGB" // Color
    //             },
    //             "output_settings" :
    //                 [
    //                     {
    //                         "type" : "return-base64",
    //                         "format" : "jpg"
    //                     }
    //                 ]
    //         }
    //     );
    // }
    /** Processes the scan result */
    // displayImagesOnPage = (successful, mesg, response) => {
    //     if (!successful) { // On error
    //         console.error('Failed: ' + mesg);
    //         return;
    //     }
    //     if (successful && mesg != null && mesg.toLowerCase().indexOf('user cancel') >= 0) { // User cancelled.
    //         console.info('User cancelled');
    //         return;
    //     }
    //     var scannedImages = scanner.getScannedImages(response, true, false); // returns an array of ScannedImage
    //     for (var i = 0;
    //          (scannedImages instanceof Array) && i < scannedImages.length; i++) {
    //         var scannedImage = scannedImages[i];
    //         this.processScannedImage(scannedImage);
    //     }
    // }

    /** Images scanned so far. */
    // imagesScanned = [];
    /** Processes a ScannedImage */
    //  processScannedImage = (scannedImage) => {
    //      let scandedImage = this.state.imagesScanned;
    //      scandedImage.push(scannedImage);
    //      this.setState({
    //          imagesScanned: scandedImage
    //      });
    //     var elementImg = scanner.createDomElementFromModel({
    //         'name': 'img',
    //         'attributes': {
    //             'class': 'scanned',
    //             'src': scannedImage.src
    //         }
    //     });
    //     document.getElementById('images').appendChild(elementImg);
    // }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        File Entry
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-archive"></i> Archive</li>
                        <li className="active">File Entry</li>
                    </ol>
                </section>
                <section className="content">
                <Loader loaded={this.state.isLoaded}>
                    <div className="row">
                        <div className="col-lg-12 col-xs-12 col-md-12">
                            <div className="box box-default">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Entry File</h3>
                                </div>

                                <div className="box-body" style={{minHeight:"300px"}}>
                                    <form ref={this.myForm} className="form-inline">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputFile">Choose File : </label>
                                            <input type="file" accept="image/x-png,image/gif,image/jpeg" className="form-control" onChange={this.onChangeHandler} name="file" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputFile">Scan Code : </label>
                                            <input type="number" onChange={this.changeHandler} min="0" style={{ width: "350px" }} placeholder="Scan Code" className="form-control" name="bar_code" />

                                        </div>
                                        &nbsp; <button type="button" disabled={this.state.isBtnDisabled} onClick={this.submitHandler} className="btn btn-primary btn-md">{this.state.isBtnDisabled ? 'Uploading' : 'Upload'}</button>
                                        {
                                            this.state.error?
                                                
                                                <div className="alert alert-danger">
                                                    <strong>Error !</strong>
                                                    <ul>
                                                        { 

                                                            this.state.errorMessage
                                                        
                                                        }
                                                    </ul>
                                                </div>
                                            :
                                            ''
                                        }
                                    </form>

                                    <br />

                                    <div className="row">

                                        {
                                            this.state.attachtedFiles.map((item, index) =>
                                                <div key={index} style={{ marginTop: "20px" }} className="col-md-2 col-lg-2 col-sm-2">
                                                    <span onClick={() => { this.removeFile(index) }} style={
                                                        {
                                                            border: "2px solid",
                                                            position: "absolute",
                                                            borderRadius: "30px",
                                                            width: "24px",
                                                            marginLeft: "70%",
                                                            color: "#bf1920",
                                                            textAlign: "center",
                                                            cursor: "pointer"
                                                        }

                                                    }>
                                                        <i className="fa fa-remove"></i>
                                                    </span>
                                                    <img src={item.src} alt="img" className="img-thumbnail" style={{ width: "200px", height: "150px" }} />
                                                </div>
                                            )}

                                    </div>
                                </div>

                                <div className="box-footer">

                                </div>

                            </div>
                        </div>
                    </div>
                </Loader>

                </section>
            </div>
        );
    }
}

export default FileEntry;
