
import React, { Component } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Loader from 'react-loader';
import Server from './../../service/Server'
const BASEURL = Server.baseUrl;
document.title = "File Content";

class FileContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            isLoaded: false,
            forms:[],
            form_title:"",
            form_content:[],
            fileList:[]
        }
    }

    getCategories = (calllBack =  () => {}) => {
        axios.get(`${BASEURL}/getCategories`)
            .then(response => {
                var getRes = response.data;

                this.setState({
                    categories: getRes.data,
                    isLoaded: true,
                    forms:[]
                },() => {
                    calllBack();
                })
            })
            .catch(error => console.log(error));
    }

    getScaningFile = () => {
        axios.get(`${BASEURL}/getScaningFile`)
            .then(response => {
                var getRes = response.data;
                this.setState({
                    fileList: getRes.data,
                    isLoaded: true,
                    forms:[]
                },() => {
                    // calllBack();
                })
            })
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getScaningFile();
    }

    render() {

        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Input Form
                    <small>Control panel</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><i className="fa fa-archive"></i> Archive</li>
                        <li className="active">Input Form</li>
                    </ol>
                </section>
                <section className="content">
                    <Loader loaded={this.state.isLoaded}>

                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <div className="box box-default">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">File List</h3>
                                    </div>
                                    <div className="box-body">
                                        <table style={{ width: "100%" }} className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Code</th>
                                                    <th>Total File</th>
                                                    <th>Scan Complete</th>
                                                    <th>Input Complete</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {this.state.fileList.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>{item.code}</td>
                                                            <td>{item.total_files}</td>
                                                            <td>{item.scan_complete}</td>
                                                            <td>{item.input_complete}</td>
                                                            <td>
                                                                <NavLink to={"/input_content/"+item.code}>
                                                                    <button type="button" className="btn btn-info btn-sm"><i className="fa fa-edit"></i> Input Content</button>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Loader>


                    <div className="modal fade" id="modal-default">

                        <div className="modal-dialog">

                            <form ref={this.myForm} role="form" onSubmit={this.submitHandler}>

                                <div className="modal-content">

                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">{this.state.form_title}</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="box-body">

                                            {
                                                this.state.forms.map((item, index) =>
                                                    <div key={index} className="form-group">
                                                        <label htmlFor="name">{item.label}</label>
                                                        {
                                                            item.attributes.name === "select" ? 
                                                            <select onChange={this.changeHandler} value={this.state.form_content[item.name][0]} name={item.name} className="form-control">
                                                                <option value="">Select Gender</option>
                                                                {
                                                                    item.attributes.hasoption===true && item.attributes.options.length > 0 ?
                                                                    item.attributes.options.map((option,optionKey) => 
                                                                        <option key={optionKey} value={option.value}>{option.name}</option>
                                                                    )
                                                                    :
                                                                    ""
                                                                }
                                                            </select>
                                                            :
                                                            (
                                                            item.attributes.name === "textarea" ? 
                                                            <textarea onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name}></textarea>
                                                            :
                                                            (
                                                            item.attributes.name === "checkbox" ? 
                                                            <div>
                                                                {
                                                                    item.attributes.options.map((option,optionKey)=>
                                                                        <div key={optionKey}>
                                                                            <span>{option.name}</span> <input type={item.attributes.name} name={item.name} checked={this.state.form_content[item.name][optionKey] === option.value ? true: false } value={option.value} onChange={this.changeHandler}/>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            // <input type={item.attributes.name} onChange={this.changeHandler} name={item.name} />
                                                            :
                                                            <input type={item.attributes.name} onChange={this.changeHandler} className="form-control" value={this.state.form_content[item.name][0]} name={item.name} placeholder="Enter Full Name" />
                                                            )
                                                            )
                                                        }
                                                        
                                                    </div>
                                                )
                                            }

                                        </div>

                                        <div className="box-footer">
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger">
                                                        <strong>Error !</strong>
                                                        <ul>
                                                            {

                                                                this.state.errorMessage

                                                            }
                                                        </ul>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                        </div>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left" id="closeBtn" data-dismiss="modal">Close</button>
                                        <button type="submit" disabled={this.state.isBtnDisabled} className="btn btn-primary">{this.state.isBtnDisabled?'Saveing' : 'Save'}</button>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>


                </section>
            </div>

        );
    }
}

export default FileContent;
